import React, { useEffect, useRef, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Styles from './home.page.module.css';
import { getCoverCarousel, getHomePageData, getThumbnailCarousel } from '../../services/home.services';
import { carouselCoverType, carouselThumbnailType } from '../../types/carousel.types';
import Header from '../../components/header/header';
import { pageDataType } from '../../types/pageData.types';
import { getImagePath, getRefData } from '../../services/utils.services';
import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { referenceDataType } from '../../types/reference.types';
import RECAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Footer from '../../components/footer/footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';

function Home () {

    const [carouselCoverItems, setCarouselCoverItems] = useState<Array<carouselCoverType>>([]);
    const [carouselEquipmentItems, setCarouselEquipmentItems] = useState<Array<carouselCoverType>>([]);
    const [carouselSkincareItems, setCarouselSkincareItems] = useState<Array<carouselCoverType>>([]);
    const [pageData, setPageData] = useState<Array<pageDataType>>([]);
    const [isReadyCover, setIsReadyCover] = useState<boolean>(false);
    const [isReadyEquipment, setIsReadyEquipment] = useState<boolean>(false);
    const [isReadySkincare, setIsReadySkincare] = useState<boolean>(false);
    const [refData, setRefData] = useState<Array<referenceDataType>>([]);
    const [enableSubmitBttn, setEnableSubmitBttn] = useState<boolean>(false);
    const [contactName, setContactName] = useState<string>('');
    const [contactEmail, setContactEmail] = useState<string>('');
    const [contactPhone, setContactPhone] = useState<string>('');
    const [contactMessage, setContactMessage] = useState<string>('');
    const [verifyStatus, setVerifyStatus] = useState<boolean>(false);
    const [reCaptchaMessage, setReCaptchaMessage] = useState<string> ('Please check in a box to verify yourself');
    const [showReCaptcha, setShowReCaptcha] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');

    const reCaptchaRef: any = useRef(null);

    const responsiveMultiCarousel = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 420 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 420, min: 0 },
          items: 1
        }
      };

    // const handleCaptchaChange = (value: any) => {
    //     if (value) {
    //         setVerifyStatus(true);
    //     } else {
    //         setVerifyStatus(false);
    //         reCaptchaRef.current.reset();
    //     }
    // }

    const handleSubmitMessage = async () => {
        // const token = await reCaptchaRef.current?.getValue() || '';
        // await reCaptchaRef.current.reset();
        // await axios.post(`${process.env.REACT_APP_HOST}/util/captchacheck`, {
        //     token: String(token)
        // })
        // .then((res) => {
        //     if(res.status == 200) {
        //         //TODO: submit message
        //         setContactName('');
        //         setContactEmail('');
        //         setContactPhone('');
        //         setContactMessage('');
        //     } else {
        //         setReCaptchaMessage('Some problem has occured, please reload the website');
        //         reCaptchaRef.current.reset();
        //     }
        // })
    }

    // const handleCaptchaExp = () => {
    //     setReCaptchaMessage('The verification has expired, please reload the website');
    //     setVerifyStatus(false);
    // }

    useEffect(() => {
        document.title = 'BiotechSG | Home';
      }, []);
      
    useEffect(() => {
        (
            async() => {
                const ccres = await getCoverCarousel() || [];
                await setCarouselCoverItems(ccres);
                await setIsReadyCover(true);

                const ecres = await getThumbnailCarousel('equipment') || [];
                await setCarouselEquipmentItems(ecres);
                await setIsReadyEquipment(true);

                const scres = await getThumbnailCarousel('skincare') || [];
                await setCarouselSkincareItems(scres);
                await setIsReadySkincare(true);

                const refEmail = await getRefData(1);
                const refPhoneNo = await getRefData(2);
                await setRefData([...refPhoneNo, ...refEmail]);

            }
        )();
    }, []);

    useEffect(() => {
        try {
            getHomePageData()
            .then((res) => res as any)
            .then((jsonres: Array<pageDataType>) => {
                setPageData(jsonres);
            });
        } catch(err) {
            console.log(err);
        }
    }, [])

    useEffect(() => {
        if (contactName && contactEmail && contactMessage) {
            setShowReCaptcha(true);
            setErrorMsg('');
        } else {
            setErrorMsg('Please filled in required field (*)');
        }
    }, [contactName, contactEmail, contactMessage])

    useEffect(() => {
        if (contactEmail.replace(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gm, '') != '') {
            setEnableSubmitBttn(false);
            return;
        }
        if (contactName && contactEmail && contactMessage && verifyStatus) {
            setEnableSubmitBttn(true);
            setErrorMsg('');
        } else {
            setEnableSubmitBttn(false);
        }
    }, [contactName, contactEmail, contactMessage, verifyStatus])

    useEffect(() => {
        if (contactEmail.replace(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gm, '') != '') {
            setErrorMsg('Email is invalid');
        } else {
            setErrorMsg('');
        }
    }, [contactEmail])

    return (
        <div className={`${Styles.pageContainer}`}>
            <div className={`${Styles.easyScrollPad}`}></div>
            <div className={`${Styles.contentContainer}`}>
                { isReadyCover ?
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    emulateTouch={true}
                    showStatus={false}
                >
                    {
                        carouselCoverItems.map((item: carouselCoverType) => {
                            return (
                                <div className={`${Styles.carouselItem}`} key={`carousel-cover-${item.item_name}`}>
                                    <img src={item.img_src} />
                                    <Link className={`${Styles.headerCarouselTextContainer} flex flex-col items-start`} to={item.nav_path ? item.nav_path : '#'}>
                                        <p className={`${Styles.headerCarouselTextMain}`}>{item.title}</p>
                                        <p className={`${Styles.headerCarouselTextSub}`}>{item.description}</p>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </Carousel> : null
                }
                <div id={`about-us`} className={`maxWidthContent ml-auto mr-auto`}>
                    <div className={`my-8 mb-8`}>
                        <Header title={'ABOUT US'} color={'#00bcd4'} isUnderline={true} fontSize={'1.25em'}/>
                    </div>
                    <div className={`flex flex-wrap justify-between`}>
                        <div className={`flex-auto max-w-3xl p-4 flex flex-col justify-center ml-auto mr-auto ${Styles.textMinWidth}`}>
                            <p className={`text-justify`}>{pageData.find((item) => item.data_name == 'about-us')?.value}</p>
                        </div>
                        <div className={`flex-auto`}></div>
                        <div className={`flex-auto max-w-sm p-4 ml-auto mr-auto`}>
                            <img src={getImagePath(Number(pageData.find((item) => item.data_name == 'about-us-image')?.value))} />
                        </div>
                    </div>
                </div>
                <div id={`equipment-carousel`} className={`py-10 maxWidthContent ml-auto mr-auto`}>
                    <div className={`my-8 mb-8`}>
                        <Header title={'OUR EQUIPMENTS'} color={'#00bcd4'} isUnderline={true} fontSize={'1.25em'}/>
                    </div>
                    <div className={`${Styles.maxWidthMuitiCarousel}`}>
                        { isReadyEquipment ?
                        <CarouselMulti
                            responsive={responsiveMultiCarousel}
                            centerMode={false}
                        >
                            {
                                carouselEquipmentItems.map((item: carouselThumbnailType) => {
                                    return (
                                        <Link className={`${Styles.carouselItem}`} key={`carousel-equipment-${item.item_name}`} to={item.nav_path}>
                                            <img className={`${Styles.MultiCarouselItemImg} ml-auto mr-auto`} src={item.img_src} />
                                            <div className={`flex flex-col items-center`}>
                                                <p className={`text-lg font-bold`}>{item.title}</p>
                                                <p className={``}>{item.description}</p>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </CarouselMulti> : null
                        }
                    </div>
                </div>
                <div id={`skincare-carousel`} className={`py-10 maxWidthContent ml-auto mr-auto`}>
                    <div className={`my-8 mb-8`}>
                        <Header title={'OUR SKINCARES'} color={'#00bcd4'} isUnderline={true} fontSize={'1.25em'}/>
                    </div>
                    <div className={`${Styles.maxWidthMuitiCarousel}`}>
                        { isReadySkincare ?
                        <CarouselMulti
                            responsive={responsiveMultiCarousel}
                            centerMode={false}
                        >
                            {
                                carouselSkincareItems.map((item: carouselThumbnailType) => {
                                    return (
                                        <Link className={`${Styles.carouselItem}`} key={`carousel-equipment-${item.item_name}`} to={item.nav_path}>
                                            <img className={`${Styles.MultiCarouselItemImg} ml-auto mr-auto`} src={item.img_src} />
                                            <div className={`flex flex-col items-center`}>
                                                <p className={`text-lg font-bold`}>{item.title}</p>
                                                <p className={``}>{item.description}</p>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </CarouselMulti> : null
                        }
                    </div>
                </div>
                <div id={`contact-us`} className={`flex justify-center pt-5 pb-24 ml-auto mr-auto ${Styles.contactContainer}`}>
                    <div className={`maxWidthContent w-full`}>
                        <div className={`my-8 mb-8`}>
                            <Header title={'CONTACT US'} color={'#ffffff'} isUnderline={true} fontSize={'1.25em'}/>
                        </div>
                        <div className={`flex flex-row justify-evenly flex-wrap items-center`}>
                            {/* <form className={`flex flex-col flex-auto max-w-xl min-w-[350px] ${Styles.formSection}`}>
                                <div className={`mb-1`}><span className={`text-white`}>{'Name'}</span><span className={`text-red-500`}>{'*'}</span></div>
                                <input value={contactName} onChange={(change) => setContactName(change.target.value)} type={'text'} name='name' className={`mb-3 bg-transparent text-white p-1 border border-white`} />
                                <div className={`mb-1`}><span className={`text-white`}>{'Email'}</span><span className={`text-red-500`}>{'*'}</span></div>
                                <input value={contactEmail} onChange={(change) => setContactEmail(change.target.value)} type={'email'} name='email' className={`mb-3 bg-transparent text-white p-1 border border-white`} />
                                <span className={`text-white mb-1`}>{'Phone'}</span>
                                <input value={contactPhone} onChange={(change) => setContactPhone(change.target.value)} type={'tel'} name='phone' className={`mb-3 bg-transparent text-white p-1 border border-white`} />
                                <div className={`mb-1`}><span className={`text-white`}>{'Message'}</span><span className={`text-red-500`}>{'*'}</span></div>
                                <textarea value={contactMessage} onChange={(change) => setContactMessage(change.target.value)} name='message' className={`bg-transparent text-white p-1 border border-white mb-3`}></textarea>
                                { showReCaptcha ?
                                    <div>
                                        <div className={`mb-1`}><span className={`text-white`}>{reCaptchaMessage}</span></div>
                                        <RECAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} 
                                        onChange={handleCaptchaChange}
                                        ref={reCaptchaRef}
                                        onExpired={handleCaptchaExp}
                                        />
                                    </div> : null
                                }
                                <div><span className='text-red text-xs'>{errorMsg}</span></div>
                                <div><span className='text-white text-xs'>{`To submit your message, you acknowledged the information you filled will send to our staff`}</span></div>
                                <div><button onClick={handleSubmitMessage} disabled={!enableSubmitBttn} type={'submit'} className={`border border-white font-bold text-white py-2 px-3 my-3 ${Styles.submitMsgBttn}`}>{'Send'}</button></div>
                            </form> */}
                            <div className={`flex justify-center items-center flex-auto max-w-sm ${Styles.contactInfoSection}`}>
                                <div className={`${Styles.contactInfoContainer} w-64 h-64 flex flex-col items-center justify-center`}>
                                    <div>
                                        <p className={`font-medium text-lg text-white`}>{`Email: ${refData.find((item => item.item_name == 'contact-email'))?.value}`}</p>
                                        <p className={`font-medium text-lg text-white`}>{`Phone: ${refData.find((item => item.item_name == 'contact-phone'))?.value}`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default Home;
