import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Modal from '../../components/modal/modal';
import { addPath, addRef, addUser, checkIsUsedUsername, deleteFile, deletePath, deleteRef, deleteUser, getAllFile, getAllPath, getAllRef, getAllUser, updateFileInfo, updatePath, updateRef, updateUser, uploadFile } from '../../services/admin.service';
import { FileType } from '../../types/file.types';
import { PathType, referenceDataType } from '../../types/reference.types';
import { UserType } from '../../types/user.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import Styles from './etcConfig.page.module.css';
import { resetPass } from '../../services/auth.service';

function EtcConfig () {
    const [userData, setUserData] = useState<Array<UserType>>([]);
    const [pathData, setPathData] = useState<Array<any>>([]);
    const [fileData, setFileData] = useState<Array<FileType>>([]);
    const [refData, setRefData] = useState<Array<any>>([]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<string>('');
    const [isReadyUser, setIsReadyUser] = useState<boolean>(false);
    const [isReadyPath, setIsReadyPath] = useState<boolean>(false);
    const [isReadyFile, setIsReadyFile] = useState<boolean>(false);
    const [isReadyRef, setIsReadyRef] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<any>({});
    const [userUpdate, setUserUpdate] = useState<any>({});
    const [pathUpdate, setPathUpdate] = useState<any>({});
    const [fileUpdate, setFileUpdate] = useState<any>({});
    const [refUpdate, setRefUpdate] = useState<any>({});
    const [pwdUpdate, setPwdUpdate] = useState<any>({});
    const [actionButtonStatus, setActionButtonStatus] = useState<boolean>(false);
    const [msgAlert, setMsgAlert] = useState<string>('');

    const uploadFileRef: any = useRef(null);

    const modalDefaultComp = () => { return (
        <div>
            <p>{'Nothing to show'}</p>
        </div>
    )}

    const modalAddComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{modalInfo.title ? modalInfo.title: 'ADD'}</span>
            { modalInfo.type == 'USER' ?
                <div>
                    <div>
                        <span className={`block`}>Username*</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, username: value.target.value})}} value={userUpdate.username || ''} name='username' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Password*</span><span className='text-xs block'>{` should be changed after user access this user`}</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, password: value.target.value})}} value={userUpdate.password || ''} name='password' type={'password'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Confirm Password*</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, confirm_password: value.target.value})}} value={userUpdate.confirm_password || ''} name='confirmPassword' type={'password'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Name*</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, name: value.target.value})}} value={userUpdate.name || ''} name='name' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Email*</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, email: value.target.value})}} value={userUpdate.email || ''} name='email' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Role*</span><span className='text-xs block'>{`e.g. ADMIN or BASIC`}</span>
                        <input maxLength={5} onChange={(value) => {setUserUpdate({...userUpdate, role: value.target.value.toUpperCase()})}} value={userUpdate.role || ''} name='role' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Active*</span>
                        <select onChange={(value) => {setUserUpdate({...userUpdate, available: value.target.value == 'TRUE' ? true : false})}} value={userUpdate.available ? 'TRUE': 'FALSE'} name='available' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                            <option value={"TRUE"}>YES</option>
                            <option value={"FALSE"}>NO</option>
                        </select>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'PATH' ?
                <div>
                    <div>
                        <span className={`block`}>Path*</span>
                        <input onChange={(value) => {setPathUpdate({...pathUpdate, path: value.target.value})}} value={pathUpdate.path || ''} name='path' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'FILE' ?
                <div>
                    <div>
                        <span className={`block`}>File*</span>
                        <input ref={uploadFileRef} accept="*" id="addFile" onChange={(value) => {handleUploadFile(value)}} name='file' type={'file'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                        { fileUpdate.file ? <FontAwesomeIcon className='cursor-pointer ml-2' icon={faXmark} onClick={() => {setFileUpdate({...fileUpdate, img: null}); uploadFileRef.current!.value = null;}}/> : null}
                    </div>
                    <div>
                        <span className={`block`}>Tag</span>
                        <input onChange={(value) => {setFileUpdate({...fileUpdate, tag: value.target.value})}} value={fileUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>{'Public or Private*'}</span>
                        <select onChange={(value) => {setFileUpdate({...fileUpdate, file_role: value.target.value})}} value={fileUpdate.file_role || 'PRIVA'} name='fileRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                            <option value={"PUBLI"}>Public</option>
                            <option value={"PRIVA"}>Private</option>
                        </select>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'REF' ?
                <div>
                    <div>
                        <span className={`block`}>{`Name (key)*`}</span>
                        <input onChange={(value) => {setRefUpdate({...refUpdate, item_name: value.target.value})}} value={refUpdate.item_name || ''} name='itemName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Value*</span>
                        <input onChange={(value) => {setRefUpdate({...refUpdate, value: value.target.value})}} value={refUpdate.value || ''} name='value' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                </div> :null
            }
            <span className='block mt-4 text-xs text-red-500'>{msgAlert}</span>
            <div className='flex flex-row justify-evenly'>
                <button disabled={!actionButtonStatus} onClick={() => {
                    if (modalInfo.type == 'USER') {
                        handleAdd(userUpdate, modalInfo.type)
                    } else if (modalInfo.type == 'PATH') {
                        handleAdd(pathUpdate, modalInfo.type);
                    } else if (modalInfo.type == 'FILE') {
                        handleAdd(fileUpdate, modalInfo.type);
                    } else if (modalInfo.type == 'REF') {
                        handleAdd(refUpdate, modalInfo.type);
                    }
                }} className={`border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600 ${actionButtonStatus ? '' : 'cursor-not-allowed'}`}>{'Add'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalEditComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{modalInfo.title ? modalInfo.title : 'Edit'}</span>
            {modalInfo.type == 'CHANGEPWD' ? null : <span className='font-bold'>{`Just edit only fields that you want to change for ID: ${modalInfo.id}`}</span>}
            { modalInfo.type == 'USER' ?
                <div>
                    <div>
                        <span className={`block`}>Name*</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, name: value.target.value})}} value={userUpdate.name || ''} name='name' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Email*</span>
                        <input onChange={(value) => {setUserUpdate({...userUpdate, email: value.target.value})}} value={userUpdate.email || ''} name='email' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Role*</span>
                        <input maxLength={5} onChange={(value) => {setUserUpdate({...userUpdate, role: value.target.value.toUpperCase()})}} value={userUpdate.role || ''} name='role' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Active*</span>
                        <select onChange={(value) => {setUserUpdate({...userUpdate, available: value.target.value == 'TRUE' ? true : false})}} value={userUpdate.available ? 'TRUE': 'FALSE'} name='available' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                            <option value={"TRUE"}>YES</option>
                            <option value={"FALSE"}>NO</option>
                        </select>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'PATH' ?
                <div>
                    <div>
                        <span className={`block`}>Path*</span>
                        <input onChange={(value) => {setPathUpdate({...pathUpdate, path: value.target.value})}} value={pathUpdate.path || ''} name='path' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'FILE' ?
                <div>
                    <div>
                        <span className={`block`}>Tag</span>
                        <input onChange={(value) => {setFileUpdate({...fileUpdate, tag: value.target.value})}} value={fileUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>{'Public or Private*'}</span>
                        <select onChange={(value) => {setFileUpdate({...fileUpdate, file_role: value.target.value})}} value={fileUpdate.file_role || 'PRIVA'} name='fileRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                            <option value={"PUBLI"}>Public</option>
                            <option value={"PRIVA"}>Private</option>
                        </select>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'REF' ?
                <div>
                    <div>
                        <span className={`block`}>{`Name (key)*`}</span>
                        <input onChange={(value) => {setRefUpdate({...refUpdate, item_name: value.target.value})}} value={refUpdate.item_name || ''} name='itemName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Value*</span>
                        <input onChange={(value) => {setRefUpdate({...refUpdate, value: value.target.value})}} value={refUpdate.value || ''} name='value' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                </div> :null
            }
            { modalInfo.type == 'CHANGEPWD' ?
                <div>
                    <div>
                        <span className={`block`}>Old Password*</span>
                        <input onChange={(value) => {setPwdUpdate({...pwdUpdate, old_pass: value.target.value})}} value={pwdUpdate.old_pass || ''} name='oldPassword' type={'password'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>New Password*</span>
                        <input onChange={(value) => {setPwdUpdate({...pwdUpdate, new_pass: value.target.value})}} value={pwdUpdate.new_pass || ''} name='newPassword' type={'password'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                    <div>
                        <span className={`block`}>Confirm New Password*</span>
                        <input onChange={(value) => {setPwdUpdate({...pwdUpdate, confirm_pass: value.target.value})}} value={pwdUpdate.confirm_pass || ''} name='confirmPassword' type={'password'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    </div>
                </div> :null
            }
            <span className='block mt-4 text-xs text-red-500'>{msgAlert}</span>
            <div className='flex flex-row justify-evenly'>
                <button disabled={!actionButtonStatus} onClick={() => {
                    if (modalInfo.type == 'USER') {
                        handleUpdate(Number(userUpdate.user_id), userUpdate, modalInfo.type);
                    } else if (modalInfo.type == 'PATH') {
                        handleUpdate(Number(modalInfo.id), pathUpdate, modalInfo.type);
                    } else if (modalInfo.type == 'FILE') {
                        handleUpdate(Number(modalInfo.id), fileUpdate, modalInfo.type);
                    } else if (modalInfo.type == 'REF') {
                        handleUpdate(Number(modalInfo.id), refUpdate, modalInfo.type);
                    } else if (modalInfo.type == 'CHANGEPWD') {
                        handleUpdate(Number(modalInfo.id), pwdUpdate, modalInfo.type);
                    }
                }} className={`border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600 ${actionButtonStatus ? '' : 'cursor-not-allowed'}`}>{'Edit'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalDeleteComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{modalInfo.title ? modalInfo.title : 'DELETE'}</span>
            { modalInfo.type == 'USER' ?
                <div>
                    <span className='font-bold'>{`Are you sure to delete user as username: ${modalInfo.id} (${modalInfo.data.name})?`}</span>
                </div> :null
            }
            { modalInfo.type == 'PATH' ?
                <div>
                    <span className='font-bold'>{`Are you sure to delete path as path ID: ${modalInfo.id} (${modalInfo.data.path})?`}</span>
                </div> :null
            }
            { modalInfo.type == 'FILE' ?
                <div>
                    <span className='font-bold'>{`Are you sure to delete file as file ID: ${modalInfo.id} (${modalInfo.data.original_file_name})?`}</span>
                </div> :null
            }
            { modalInfo.type == 'REF' ?
                <div>
                    <span className='font-bold'>{`Are you sure to delete reference as ref ID: ${modalInfo.id} (${modalInfo.data.item_name})?`}</span>
                </div> :null
            }
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => {
                    if (modalInfo.type == 'USER') {
                        handleDelete(Number(modalInfo.data.user_id), modalInfo.data.name, modalInfo.type);
                    } else if (modalInfo.type == 'PATH') {
                        handleDelete(Number(modalInfo.id), modalInfo.data.path, modalInfo.type);
                    } else if (modalInfo.type == 'FILE') {
                        handleDelete(Number(modalInfo.id), modalInfo.data.original_file_name, modalInfo.type);
                    } else if (modalInfo.type == 'REF') {
                        handleDelete(Number(modalInfo.id), modalInfo.data.item_name, modalInfo.type);
                    }
                }} className='border-2 border-slate-400 bg-red-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-red-600'>{'YES'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'NO'}</button>
            </div>
        </div>
    )}

    const handleClick = async (mode: string, info: any) => {
        // await setModalInfo({});
        await setUserUpdate({});
        await setPathUpdate({});
        await setFileUpdate({});
        await setRefUpdate({});
        await setMsgAlert('');
        await setActionButtonStatus(true);
        if (mode == "ADD") {
            await setModalInfo({type: info.type, title: info.title});
        } else if (mode == "EDIT") {
            await setModalInfo({id: info.id, data: info.data, type: info.type, title: info.title});
            if (info.type == 'USER') {
                await setUserUpdate({...info.data});
            } else if (info.type == 'PATH') {
                await setPathUpdate({...info.data});
            } else if (info.type == 'FILE') {
                await setFileUpdate({...info.data});
            } else if (info.type == 'REF') {
                await setRefUpdate({...info.data});
            } else if (info.type == 'CHANGEPWD') {
                await setPwdUpdate({...info.data});
            }
        } else if (mode == 'DELETE') {
            await setModalInfo({id: info.id, data: info.data, type: info.type, title: info.title});
        }
        await setIsShowModal(false);
        await setModalMode(mode);
        await setIsShowModal(true);
    }

    const handleDelete = async (id: number, name: string, type: string) => {
        if (type == 'USER') {
            await deleteUser(id)
            .then((x) => alert(`Delete ${type.toLowerCase()} name ${name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to delete user. Please check in console log : ${err[0].err.response.data.msg}`));
        } else if (type == 'PATH') {
            await deletePath(id)
            .then((x) => alert(`Delete path ID ${id} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to delete path. Please check in console log`));
        } else if (type == 'FILE') {
            await deleteFile(id)
            .then((x) => alert(`Delete file ${name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to delete file. Please check in console log`));

        } else if (type == 'REF') {
            await deleteRef(id)
            .then((x) => alert(`Delete reference name: ${name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => {
                alert(`Error to delete reference info. Please check in console log (${err[0].err.response.data.msg})`)
            });
        }
    }

    const handleAdd = async (data: any, type: string) => {
        if (type == 'USER') {
            if (!data.available) {
                data.available = false;
            }
            if (!data.username || !data.password || !data.confirm_password || !data.name || !data.email || !data.role || !data.available) {
                alert('Required field cannot be empty (*)');
                return;
            }
            if (String(data.username).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
                alert('Username only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
                return;
            }
            if (data.email.replace(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gm, '') != '') {
                alert('Email is invalid');
            }
            const checkUserRes = await checkIsUsedUsername(data.username)
            .then((x: any) => !(x.res))
            .catch((err) => alert(`Error to add user. Please check in console log`));

            if(!checkUserRes) {
                alert('Username has been taken');
                return;
            }
            if (!actionButtonStatus) {
                alert(msgAlert);
                return;
            }

            await addUser(data)
            .then((x) => alert(`Add ${type.toLowerCase()}, name ${data.name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to add user. Please check in console log`));

        } else if (type == 'PATH') {
            if(!data.path) {
                alert('Required field cannot be empty (*)');
                return;
            }
            if (String(data.path).replace(/[a-zA-Z0-9\-_/]/gm, '').length > 0) {
                alert('Path only allow alphabet, number, slash, underscore, and dash (e.g. spacebar not allowed)');
                return;
            }
            await addPath(data)
            .then((x) => alert(`Add path ${data.path} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to add path. Please check in console log`));

        } else if (type == 'FILE') {
            if (!data.file) {
                alert('Require file to add');
                return;
            } else if (!data.file_role) {
                setFileUpdate({...fileUpdate, file_role: 'PRIVA'});
                data.file_upload = 'PRIVA';
            }
            const formData = new FormData();
            formData.append('fileupload', data.file);
            formData.append('tag', data.tag || '');
            formData.append('file_role', data.file_role);
            await uploadFile(formData)
            .then((x) => alert (`Add file successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert (`Error to add file. Please check in console log`));

        } else if (type == 'REF') {
            if(!data.item_name){
                alert('Require name or key');
                return
            } else if (!data.value){
                alert('Require value');
                return
            }
            await addRef(data)
            .then((x) => alert(`Add reference ${data.item_name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to add reference. Please check in console log :${err[0].err.response.data.msg}`));
            
        }
    }

    const handleUpdate = async (id: number, data: any, type: string) => {
        if (type == 'USER') {
            if (!data.available) {
                data.available = false;
            }
            if (!data.name || !data.email || !data.role || !data.available) {
                alert('Required field cannot be empty (*)');
                return;
            }
            if (data.email.replace(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gm, '') != '') {
                alert('Email is invalid');
            }
            if (!actionButtonStatus) {
                alert(msgAlert);
                return;
            }

            const realUpdateData = await compareData(data);
            await updateUser(id, realUpdateData as any)
            .then((x) => alert(`Update ${type.toLowerCase()}, name ${data.name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update user. Please check in console log`));
        } else if (type == 'PATH') {
            if(!data.path) {
                alert('Required field cannot be empty (*)');
                return;
            }
            if (String(data.path).replace(/[a-zA-Z0-9\-_/]/gm, '').length > 0) {
                alert('Path only allow alphabet, number, slash, underscore, and dash (e.g. spacebar not allowed)');
                return;
            }
            const realUpdateData = await compareData(data);
            await updatePath(id, realUpdateData as any)
            .then((x) => alert(`Update path ${data.path} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update path. Please check in console log`));

        } else if (type == 'FILE') {
            if (!data.file_role) {
                alert('Require file role (public or private)');
                return
            }
            await updateFileInfo(data.file_id, data)
            .then((x) => alert(`Update file ${data.original_file_name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update file info. Please check in console log`));

        } else if (type == 'REF') {
            if (!data.item_name) {
                alert('Require name or key');
                return
            } else if (!data.value) {
                alert('Require value');
                return;
            }
            await updateRef(data.item_id, data)
            .then((x) => alert(`Update reference item ${data.item_name} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update file info. Please check in console log`));
            
        } else if (type == 'CHANGEPWD') {
            if (!data.old_pass) {
                alert('Require Old Password');
                return;
            }
            if (!data.new_pass) {
                alert('Require New Password');
                return;
            }
            if (!data.confirm_pass) {
                alert('Require Confirm Password');
                return;
            }
            await resetPass((localStorage.getItem('username') || ""), data.old_pass, "OLDPASSWORD", data.new_pass)
            .then((x) => alert(`change password of username ${data.username} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to change password. Please check in console log ${err[0].err.response.data.msg}`));
        }
    }

    const handleUploadFile = async (item: any) => {
        if (item.target.files.length > 0) {
            await setFileUpdate({...fileUpdate, file: item.target.files[0]});
        }
    }

    const compareData = (data: any) => {
        let updateData = {};
        Object.keys(data).forEach(key => {
            let tempData = {}
            if (modalInfo.data[key] != data[key]) {
                tempData = {[key] : data[key]}
            }
            updateData = {...updateData, ...tempData}
        })
        return updateData;
    }

    useEffect(()=> {
        getAllUser().then((res) => setUserData(res as Array<UserType>)).then((x) => setIsReadyUser(true));
        getAllPath().then((res) => setPathData(res as Array<PathType>)).then((x) => setIsReadyPath(true));
        getAllFile().then((res) => setFileData(res as Array<FileType>)).then((x) => setIsReadyFile(true));
        getAllRef().then((res) => setRefData(res as Array<referenceDataType>)).then((x) => setIsReadyRef(true));
    }, [])

    useEffect(() => {
        setActionButtonStatus(true);
        setMsgAlert('');
        if (modalInfo.type == 'USER'){
            if (String(userUpdate.password).length < 10 && userUpdate.password) {
                setActionButtonStatus(false);
                setMsgAlert('Password must longer or equals 10 characters');
                return;
            }
            if(userUpdate.password != userUpdate.confirm_password && userUpdate.confirm_password && userUpdate.password) {
                setActionButtonStatus(false);
                setMsgAlert('Password and Confirm Password mismatch');
                return;
            }
            setActionButtonStatus(true);
            setMsgAlert('');
        }
    }, [userUpdate])

    useEffect(() => {
        if (modalInfo.type == 'CHANGEPWD'){
            if (String(pwdUpdate.new_pass).length < 10 && pwdUpdate.new_pass) {
                setActionButtonStatus(false);
                setMsgAlert('Password must longer or equals 10 characters');
                return;
            }
            if(pwdUpdate.new_pass != pwdUpdate.confirm_pass && pwdUpdate.new_pass && pwdUpdate.confirm_pass) {
                setActionButtonStatus(false);
                setMsgAlert('Password and Confirm Password mismatch');
                return;
            }
            setActionButtonStatus(true);
            setMsgAlert('');
        }
    }, [pwdUpdate])

    return (
        <div>
            <Modal 
            component={modalMode == 'ADD' ? modalAddComp : modalMode == 'EDIT' ? modalEditComp : modalMode == 'DELETE' ? modalDeleteComp : modalDefaultComp} 
            isShow={isShowModal} />
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'Other configurations'} isUnderline={false}/>
                    {/* USER */}
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage User</span>
                        {isReadyUser ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {type: 'USER', title: 'ADD USER'})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add User</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Username</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Email</th>
                                        <th className='text-center'>Role</th>
                                        <th className='text-center'>Active</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.username}</td>
                                                    <td className='text-center'>{item.name}</td>
                                                    <td className='text-center'>{item.email}</td>
                                                    <td className='text-center'>{item.role}</td>
                                                    <td className='text-center'>{item.available ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center'>
                                                        { (localStorage.getItem('username') || '') == item.username ?
                                                            <button onClick={() => handleClick('EDIT', {type: 'CHANGEPWD', title: 'CHANGE PASSWORD', data: item})} className='border-2 border-slate-400 rounded-lg px-6 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Change Password</button> : null
                                                        }
                                                        <button onClick={() => handleClick('EDIT', {id: item.username, type: 'USER', title: 'EDIT USER', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.username, type: 'USER', title: 'DELETE USER', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { userData.length <= 0 && isReadyUser ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                    {/* PATH */}
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Path</span>
                        {isReadyPath ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {type: 'PATH', title: 'ADD PATH'})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Path</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Path ID</th>
                                        <th className='text-center'>Path</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pathData.map((item, index) => {
                                            return (
                                                <tr key={`path-row-${index}`}>
                                                    <td className='text-center'>{item.path_id}</td>
                                                    <td className='text-center'>{item.path}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.path_id, type: 'PATH', title: 'EDIT PATH', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.path_id, type: 'PATH', title: 'DELETE PATH', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { pathData.length <= 0 && isReadyPath ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                    {/* FILE */}
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage File</span>
                        {isReadyFile ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <p className={`font-bold self-end`}>{((fileData.reduce((value, a) => value + parseInt(a.file_size), 0))/1000/1000).toFixed(4) + ' MB ALL FILES SIZE'}</p>
                        <button onClick={() => handleClick('ADD', {type: 'FILE', title: 'ADD FILE'})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add File</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>File ID</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Type</th>
                                        <th className='text-center'>Size </th>
                                        <th className='text-center'>Tag</th>
                                        <th className='text-center'>Public</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fileData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.file_id}</td>
                                                    <td className='text-center'>{item.original_file_name}</td>
                                                    <td className='text-center'>{item.file_type}</td>
                                                    <td className='text-center'>{(parseInt(item.file_size)/1000/1000).toFixed(4) + ' MB'}</td>
                                                    <td className='text-center'>{item.tag}</td>
                                                    <td className='text-center'>{item.file_role == "PUBLI" ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.file_id, type: 'FILE', title: 'EDIT FILE', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.file_id, type: 'FILE', title: 'DELETE FILE', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { fileData.length <= 0 && isReadyFile ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                    {/* REFERENCE */}
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Reference</span>
                        {isReadyRef ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {type: 'REF', title: 'ADD REFERENCE'})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Reference</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Item ID</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Value</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        refData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.item_id}</td>
                                                    <td className='text-center'>{item.item_name}</td>
                                                    <td className='text-center'>{item.value}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.item_id, type: 'REF', title: 'EDIT REFERENCE', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.item_id, type: 'REF', title: 'DELETE REFERENCE', data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { refData.length <= 0 && isReadyRef ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default EtcConfig;
