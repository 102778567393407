import axios from "axios";
import ms from "ms";

export function initial() {
    requestInterceptors();
}

function requestInterceptors() {
    axios.interceptors.request.use(
        async (config) => {
            const exp = Number(localStorage.getItem('exp')) || 0;
            const url = config.url?.includes('/auth/renewtoken') || false
            if ((!url && exp < new Date().getTime()) && exp != 0) {
                await axios(`${process.env.REACT_APP_HOST}/auth/renewtoken`, {withCredentials: true, method: 'post'})
                .then((res) => {
                    if (res.status == 200) {
                        localStorage.setItem('exp', ms(res.data.exp)+ new Date().getTime());
                        window.location.reload();
                    }
                });
            }
            return config;
        },
        error => {
            return new Promise((resolve, reject) => reject(error));
        }
    )

    axios.interceptors.response.use(
        (response) => {
        return response;
      }, (error) => {
        if ((error.response.status || 500) == 403) {
            localStorage.setItem('exp', '0');
            alert('session has been expired, please refresh to login');
        }
        return new Promise((resolve, reject) => reject(error));
      });
}