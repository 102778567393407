import Styles from './footer.module.css';

function Footer () {
    return (
        <div>
            <div className={`${Styles.container} py-5 text-light`}>
                <div className={`w-full text-center`}><span className={`text-white text-xs`}>{'2023 Bio Tech Industrial'}</span></div>
            </div>
        </div>
    )
}

export default Footer