import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Modal from '../../components/modal/modal';
import { deleteImage, getAllImgData, updateImageInfo, uploadImage } from '../../services/admin.service';
import { ImageType } from '../../types/image.types';
import Styles from './imageConfig.page.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

function ImageConfig () {
    const [imageData, setImageData] = useState<Array<ImageType>>([]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<string>('');
    const [isReady, setIsReady] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<any>({});
    const [imageUpdate, setImageUpdate] = useState<any>({});

    const uploadImgRef: any = useRef(null);

    const modalDefaultComp = () => { return (
        <div>
            <p>{'Nothing to show'}</p>
        </div>
    )}

    const modalAddComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{`ADD ${modalInfo.type ? String(modalInfo.type).toUpperCase() : 'item'}`}</span>
            <div>
                <div>
                    <span className={`block`}>Image*</span>
                    <input ref={uploadImgRef} accept="image/*" id="addPic" onChange={(value) => {handleUploadImage(value)}} name='file' type={'file'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                    { imageUpdate.img ? <FontAwesomeIcon className='cursor-pointer ml-2' icon={faXmark} onClick={() => {setImageUpdate({...imageUpdate, img: null}); uploadImgRef.current!.value = null;}}/> : null}
                </div>
                <div>
                    <span className={`block`}>Attribute*</span>
                    <input onChange={(value) => {setImageUpdate({...imageUpdate, img_attribute: value.target.value})}} value={imageUpdate.img_attribute || ''} name='attribute' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Tag</span>
                    <input onChange={(value) => {setImageUpdate({...imageUpdate, tag: value.target.value})}} value={imageUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>{'Public or Private*'}</span>
                    <select onChange={(value) => {setImageUpdate({...imageUpdate, img_role: value.target.value})}} value={imageUpdate.img_role || 'PRIVA'} name='imgRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"PUBLI"}>Public</option>
                        <option value={"PRIVA"}>Private</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleAdd(imageUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Add'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalEditComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{`Edit ${modalInfo.type ? String(modalInfo.type).toUpperCase() : 'item'}`}</span>
            <span className='font-bold'>{`Just edit only fields that you want to change for menu ID: ${modalInfo.id}`}</span>
            <div>
                <div>
                    <span className={`block`}>Attribute*</span>
                    <input onChange={(value) => {setImageUpdate({...imageUpdate, img_attribute: value.target.value})}} value={imageUpdate.img_attribute || ''} name='attribute' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Tag</span>
                    <input onChange={(value) => {setImageUpdate({...imageUpdate, tag: value.target.value})}} value={imageUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>{'Public or Private*'}</span>
                    <select onChange={(value) => {setImageUpdate({...imageUpdate, img_role: value.target.value})}} value={imageUpdate.img_role || 'PRIVA'} name='pageRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"PUBLI"}>Public</option>
                        <option value={"PRIVA"}>Private</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleUpdate(Number(modalInfo.id), imageUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Edit'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalDeleteComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'DELETE'}</span>
            <span className='font-bold'>{`Are you sure to delete image as imgage ID: ${modalInfo.id}?`}</span>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleDelete(Number(modalInfo.id))} className='border-2 border-slate-400 bg-red-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-red-600'>{'YES'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'NO'}</button>
            </div>
        </div>
    )}

    const handleUploadImage = async (item: any) => {
        if (item.target.files.length > 0) {
            await setImageUpdate({...imageUpdate, img: item.target.files[0]});
        }
    }

    const handleClick = async (mode: string, info: any) => {
        // await setModalInfo({});
        await setImageUpdate({});
        if (mode == "ADD") {
            await setModalInfo({});
        } else if (mode == "EDIT") {
            await setImageUpdate({...info.data})
            await setModalInfo({id: info.id, data: info.data});
        } else if (mode == 'DELETE') {
            await setModalInfo({id: info.id});
        }
        await setIsShowModal(false);
        await setModalMode(mode);
        await setIsShowModal(true);
    }

    const handleDelete = async (imgID: number) => {
        await deleteImage(imgID)
        .then((x) => alert(`Delete imgage as image ID ${imgID} successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to delete image. Please check in console log`));
    }

    const handleAdd = async (data: any) => {
        if (!data.img_role) {
            data.img_role = 'PRIVA'
        }
        if (!data.img_attribute || !data.img_role || !data.img) {
            alert('Required field cannot be empty (*)');
            return;
        }
        const formData = new FormData();
        formData.append('image', data.img);
        formData.append('attribute', data.img_attribute);
        formData.append('tag', data.tag || '');
        formData.append('img_role', data.img_role);
        await uploadImage(formData)
        .then((x) => alert(`Add image successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to add image. Please check in console log`));
    }

    const handleUpdate = async (imgID: number, data: any) => {
        if (!data.img_role) {
            data.img_role = imageUpdate.img_role;
        }
        if (!data.img_attribute || !data.img_role) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (!data.img_role) {
            data.display = "PRIVA"
        }
        let updateData = {};
        Object.keys(data).forEach(key => {
            let tempData = {}
            if (modalInfo.data[key] != data[key]) {
                tempData = {[key] : data[key]}
            }
            updateData = {...updateData, ...tempData}
        })
        await updateImageInfo(imgID, data)
            .then((x) => alert(`Update image as image ID ${imgID} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update image. Please check in console log`));
    }

    useEffect(() => {
                getAllImgData()
                .then((res) => setImageData(res as Array<ImageType>))
                .then((x) => setIsReady(true));
    }, [])

    return (
        <div>
            <Modal 
            component={modalMode == 'ADD' ? modalAddComp : modalMode == 'EDIT' ? modalEditComp : modalMode == 'DELETE' ? modalDeleteComp : modalDefaultComp} 
            isShow={isShowModal} />
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'Images'} isUnderline={false}/>
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Images</span>
                        <p className={`font-bold self-end`}>{((imageData.reduce((value, a) => value + parseInt(a.img_size), 0))/1000/1000).toFixed(4) + ' MB ALL IMAGES SIZE'}</p>
                        <button onClick={() => handleClick('ADD', {})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Image</button>
                        <div className={`overflow-auto max-w-[100vw]`}>
                            {
                                isReady ?
                                <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Thumbnail</th>
                                        <th className='text-center'>Image ID</th>
                                        <th className='text-center'>Attribute</th>
                                        <th className='text-center'>Size</th>
                                        <th className='text-center'>Tag</th>
                                        <th className='text-center'>Public</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        imageData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className={`text-center ${Styles.thumbImgContainer}`}><img src={item.thumb_img} alt={`thumb-${item.img_name}`}/></td>
                                                    <td className='text-center'>{item.img_id}</td>
                                                    <td className='text-center'>{item.img_attribute}</td>
                                                    <td className='text-center'>{(parseInt(item.img_size)/1000/1000).toFixed(4) + ' MB'}</td>
                                                    <td className='text-center'>{item.tag}</td>
                                                    <td className='text-center'>{item.img_role == 'PUBLI' ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center flex flex-nowrap'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.img_id, data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.img_id})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>
                            }
                            { (imageData.length <= 0 && isReady) ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ImageConfig;
