import React, { Component, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { getPageData } from '../../services/viewer.service';
import { pageDataType } from '../../types/pageData.types';
import { viewerType } from '../../types/viewer.types';
import Styles from './markdownViewer.page.module.css';

function MarkdownViewer (props: viewerType) {
    const [pageData, setPageData] = useState<Array<pageDataType>>([]);

    const handleMarkdown = (markdown: string) => {
        // REPLACE HOST TO ACTUAL HOST
        markdown = markdown.replace(/\${[a-zA-Z\-_.]+}/gm, `${process.env.REACT_APP_HOST}`);

        return markdown;
    }

    useEffect(() => {
        document.title = 'BiotechSG | ' + `${pageData.find((item) => item.data_name.includes('title'))?.value}`;
      }, []);

    useEffect(() => {
        try {
            getPageData(props.pageSlug)
            .then((res) => res as any)
            .then((jsonres: Array<pageDataType>) => {
                setPageData(jsonres);
            });
        } catch(err) {
            console.log(err);
        }
    }, [props])


    return (
        <div>
            <div className={`${Styles.pageContainer} pb-10 px-4`}>
                <div className={`${Styles.pageContent}`}>
                    <Header title={`${pageData.find((item) => item.data_name.includes('title'))?.value}`}/>
                    <ReactMarkdown 
                        remarkPlugins={[remarkGfm]}
                        components={{
                            a: ({node, ...props}) => {
                                if (JSON.stringify(node.children).includes('youtube')) {
                                    return <iframe
                                    className='ml-auto mr-auto'
                                    width={320} 
                                    height={240} 
                                    src={`${props.href}`} 
                                    title={'YTPlayer'} 
                                    allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                                    allowFullScreen
                                    ></iframe>
                                } else {
                                    return <a href={props.href}>{`${props.children.join()}`}</a>
                                }},
                            h2: ({node, ...props}) => {return <div className={`flex justify-center mt-6 mb-8`}><span className='text-2xl font-bold uppercase' {...props}/></div>},
                            h4: ({node, ...props}) => {return <span className={`font-bold text-lg my-4 block`} {...props}/>},
                            h5: ({node, ...props}) => {return <span className={`font-bold text-base my-2 block`} {...props}/>},
                            h6: ({node, ...props}) => {return <div className={`flex justify-start`}><span {...props}/></div>},
                            ul: ({node, ...props}) => {return <ul className={`list-disc pl-10`} {...props}/>},
                            ol: ({node, ...props}) => {return <ul className={`list-decimal pl-10`} {...props}/>}
                        }}
                    >
                        {`${handleMarkdown(pageData.find((item) => item.data_type.includes('MARKDOWN'))?.value || '')}`}
                    </ReactMarkdown>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default MarkdownViewer;
