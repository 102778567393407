import axios from "axios";
import { menuType } from "../types/menu.types";
import { referenceDataType } from "../types/reference.types";

export function getMenu (menu: Array<menuType>) {
    let tempMenu: Array<menuType> = [];
    tempMenu = [...menu.filter((item) => item.parent_menu == null)];
    for (let i=0; i < tempMenu.length; i++) {
        tempMenu[i].submenu = getSubmenu(tempMenu[i], menu);
    }
    return tempMenu;
}

function getSubmenu (item: menuType, allMenu: Array<menuType>) {
    let submenu = allMenu.filter((i) => i.parent_menu == item.menu_id);
    for (let i=0; i < submenu.length; i++) {
        submenu[i].submenu = getSubmenu(submenu[i], allMenu);
    }
    return submenu;
}

export function getImagePath (imgID: number) {
    if (!isNaN(imgID)){
        return `${process.env.REACT_APP_HOST}/util/getimg/${imgID}`;
    }
    return '';
}

export async function getRefData (itemID: number) {
    try {
        return await axios(`${process.env.REACT_APP_HOST}/util/ref/${itemID}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<referenceDataType>) => {
            return jsonres;
        });
    } catch(err) {
        console.log(err);
        return [];
    }
}