import Styles from './header.module.css';
import { headerCompType } from '../../types/headerComp.types';

function Header (props: headerCompType) {
    props = {
        title: props.title,
        isFullWidth: typeof props.isFullWidth == 'undefined' ? true : false,
        width: props.width || '100%',
        fontSize: props.fontSize || '2em',
        fontWight: props.fontWight || '700',
        color: props.color || `#222222`,
        justifyContent: props.justifyContent || 'center',
        isUnderline: typeof props.isUnderline == 'undefined' ? true : false ,
    }
    return (
        <div>
            <div style={{width: props.width, justifyContent: props.justifyContent}} className={`${Styles.headerCompContainer} ${props.isFullWidth ? 'flex flex-row' : ''}`}>
                <div className={`flex flex-col items-center`}>
                    <p style={{color: props.color, fontSize: props.fontSize, fontWeight: props.fontWight}}>{props.title}</p>
                    {props.isUnderline ? <div className={`h-1 inline-block`} style={{backgroundColor: props.color, width: '50%'}}></div> : null }
                </div>
            </div>
        </div>
    )
}

export default Header