import axios from 'axios';
import {carouselCoverType, carouselThumbnailType} from '../types/carousel.types';

export async function getCoverCarousel() {
    try {
        return await axios(`${process.env.REACT_APP_HOST}/carousel/cover`, {withCredentials: true, method: 'get'})
        .then((res) => res.data)
        .then((jsonres: Array<carouselCoverType>) => {
            for (let i=0; i<jsonres.length; i++) {
                jsonres[i].img_src = `${process.env.REACT_APP_HOST}/util/getimg/${jsonres[i].img_id}`
            }
            return jsonres;
        });
    } catch(err) {
        console.log(err);
        return [];
    }
}

export async function getThumbnailCarousel(tag?: string) {
    const filterTag: string = tag || '';
    try {
        return await axios(`${process.env.REACT_APP_HOST}/carousel/thumbnail`, {withCredentials: true, method: 'get'})
        .then((res) => res.data)
        .then((jsonres) => jsonres.filter((item: carouselThumbnailType) => String(item.tag || '').includes(filterTag))) 
        .then((filteredRes: Array<carouselThumbnailType>) => {
            for (let i=0; i<filteredRes.length; i++) {
                filteredRes[i].img_src = `${process.env.REACT_APP_HOST}/util/getimg/${filteredRes[i].img_id}`
            }
            return filteredRes;
        });
    } catch(err) {
        console.log(err);
        return [];
    }
}

export async function getHomePageData() {
    return new Promise((resolve, reject) => {
        try {
            axios(`${process.env.REACT_APP_HOST}/page/data/home`, {withCredentials: true})
            .then((res) => resolve(res.data))
        } catch (err) {
            console.log(err);
            resolve([])
        }
    });
}