import Styles from './navbar.module.css';
import logo from '../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { getMenu } from '../../services/utils.services';
import { menuType } from '../../types/menu.types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CookieConsent from "react-cookie-consent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { fetchMenu } from '../../services/navbar.service';


function Navbar () {
    const [toggle, setToggle] = useState<boolean>();
    const [clearNav, setClearNav] = useState<boolean>(true);
    const [menu, setMenu] = useState<Array<menuType>>([]);
    const [menuSelector, setMenuSelector] = useState<string>('');
    const [displayMenu, setDisplayMenu] = useState<Array<menuType>>([]);
    const [menuAnimator, setMenuAnimator] = useState<string>('opacity-100');

    const windowHeight = window.innerHeight;

    const handleToggle = () => {
        setToggle(!toggle);
        if (!toggle) {
            setMenuSelector('');
        }
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position >= windowHeight) {
            setClearNav(false);
        } else {
            if (window.location.href.split('/').length > 4) {
                setClearNav(false);
            } else {
                setClearNav(true);
            }
        }
      };

    const handleClickMenu = (next: boolean, item?: menuType, index?: number) => {
        if (next) {
            setMenuAnimator('opacity-0');
            setMenuSelector(menuSelector + index?.toString());
        } else {
            setMenuAnimator('opacity-0');
            setMenuSelector(menuSelector.slice(0, menuSelector.length-1));
        }
    }

      useEffect(() => {
        fetchMenu()
        .then((menuList) => {
            let myMenu = getMenu(menuList as any);
            setMenu(myMenu);
            setDisplayMenu(myMenu);
        })
      }, [])

    useEffect(() => {
        if (menuSelector.length > 0) {
            let tempMenu: Array<any> = menu;
            for(let i=0; i < menuSelector.length; i++) {
                tempMenu = tempMenu[Number(menuSelector.slice(i, i+1))].submenu;
            }
            setDisplayMenu([...tempMenu]);
        } else {
            setDisplayMenu([...menu]);
        }
        clearTimeout();
        setTimeout(()=>{setMenuAnimator('opacity-100');}, 200);
    }, [menuSelector, menuAnimator]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

      useEffect(() => {
        var href = window.location.href;
        if (href.split('/').length > 4) {
            setClearNav(false);
        }
      }, [window.location.href])

    return (
        <div className={`${Styles.navBarContainer} ${clearNav ? Styles.navBarContainerBGClear : Styles.navBarContainerBGOpaque}`}>
            {toggle ? <div className={`${Styles.toggleContent}`}>
                <FontAwesomeIcon onClick={() => handleClickMenu(false)} icon={faAngleLeft} className={`${Styles.backIconToggle} ${Styles.clickable} ${menuSelector.length > 0 ? Styles.appear : ''}`}/>
                <ul className={`${Styles.toggleMenuContainer} ${menuAnimator}`}>
                    {
                        displayMenu?.map((item: menuType, index: number) => {
                            if (item.submenu.length <= 0) {
                                if ((item.nav_path || '').includes('#')) {
                                    return <HashLink key={`menu-toggle-${item.menu_id}`} className={`${Styles.toggleItem} ${Styles.clickable}`} to={`${item?.nav_path || ''}`} onClick={handleToggle}>{item.menu_display}</HashLink>
                                } else {
                                    return <Link key={`menu-toggle-${item.menu_id}`} className={`${Styles.toggleItem} ${Styles.clickable}`} to={`${item?.nav_path || ''}`} onClick={handleToggle}>{item.menu_display}</Link>
                                }
                            } else {
                                return <li key={`menu-toggle-${item.menu_id}`} className={`${Styles.toggleItem} ${Styles.clickable}`} onClick={() => handleClickMenu(true, item, index)}>{item.menu_display}</li>
                            }
                        })
                    }
                </ul>
            </div> : null}
            <div className={`${Styles.navBarContent}`}>
                <div className={`${Styles.leftMostContainer}`}>
                    <Link key={`logo-to-home`} to={`/`}><img alt={'Bio Tech Industrial Logo'} className={`${Styles.logo}`} src={logo}/></Link>
                </div>
                <div className={`${Styles.rightMostContainer}`}>
                    <ul className={`${Styles.menuContainer} ${menuAnimator} ${menuAnimator == 'opacity-100' ? 'transition duration-200': ''} ${menuSelector.length <= 0 ? 'flex-row': 'flex-col items-end'}`}>
                        <FontAwesomeIcon icon={faAngleLeft} onClick={() => handleClickMenu(false)} className={`${Styles.backIcon} ${Styles.clickable} ${menuSelector.length > 0 ? Styles.appear : ''}`}></FontAwesomeIcon>
                        {
                            displayMenu?.map((item: menuType, index: number) => {
                                if (item.submenu.length <= 0) {
                                    if ((item.nav_path || '').includes('#')) {
                                        return <HashLink key={`menu-${item.menu_id}`} onClick={() => setMenuSelector('')} className={`${Styles.menuItem} ${Styles.clickable} ${menuSelector.length <= 0 ? '': 'my-1'}`} to={`${item?.nav_path || ''}`}>{item.menu_display}</HashLink>
                                    } else {
                                        return <Link key={`menu-${item.menu_id}`} onClick={() => setMenuSelector('')} className={`${Styles.menuItem} ${Styles.clickable} ${menuSelector.length <= 0 ? '': 'my-1'}`} to={`${item?.nav_path || ''}`}>{item.menu_display}</Link>
                                    }
                                } else {
                                    return <li key={`menu-${item.menu_id}`} className={`${Styles.menuItem} ${Styles.clickable} ${menuSelector.length <= 0 ? '': 'my-1'}`} onClick={() => handleClickMenu(true, item, index)}><p>{item.menu_display}</p></li>
                                }
                            })
                        }
                    </ul>
                    <button className={`${Styles.toggleButton} ${toggle ? Styles.change : ''}`} onClick={handleToggle}>
                        <div className={`${Styles.bar1}`}></div>
                        <div className={`${Styles.bar2}`}></div>
                        <div className={`${Styles.bar3}`}></div>
                    </button>
                </div>
            </div>
            <CookieConsent buttonStyle={{ backgroundColor: "#00bcd4", color: "#ffffff" }}>This website uses cookies to enhance the user experience. <a className={`text-xs`} href='https://www.cookiesandyou.com/'>Learn more about cookies and how to manage<FontAwesomeIcon className='ml-1' icon={faUpRightFromSquare}/></a></CookieConsent>
        </div>
    )
}

export default Navbar