import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { carouselCoverType, carouselThumbnailType } from '../../types/carousel.types';
import { pageDataType } from '../../types/pageData.types';
import { viewerType } from '../../types/viewer.types';
import Styles from './carouselConfig.page.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { addCoverCarousel, addThumbnailCarousel, deleteCoverCarousel, deleteThumbnailCarousel, getAllCoverCarousel, getAllThumbnailCarousel, updateCoverCarousel, updateThumbnailCarousel } from '../../services/admin.service';
import Modal from '../../components/modal/modal';

function CarouselConfig () {
    const [coverData, setCoverData] = useState<Array<carouselCoverType>>([]);
    const [thumbData, setThumbData] = useState<Array<carouselThumbnailType>>([]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<string>('');
    const [isReadyCover, setIsReadyCover] = useState<boolean>(false);
    const [isReadyThumb, setIsReadyThumb] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<any>({});
    const [carouselUpdate, setCarouselUpdate] = useState<any>({});

    const modalDefaultComp = () => { return (
        <div>
            <p>{'Nothing to show'}</p>
        </div>
    )}

    const modalAddComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{`ADD ${modalInfo.type ? String(modalInfo.type).toUpperCase() : 'item'}`}</span>
            <div>
                <div>
                    <span className={`block`}>Item Name*</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, item_name: value.target.value})}} value={carouselUpdate.item_name || ''} name='itemName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Title*</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, title: value.target.value})}} value={carouselUpdate.title || ''} name='title' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Description</span>
                    <textarea onChange={(value) => {setCarouselUpdate({...carouselUpdate, description: value.target.value})}} value={carouselUpdate.description || ''} name='description' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Image ID*</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, img_id: value.target.value})}} value={carouselUpdate.img_id || ''} name='imgID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Path ID</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, path_id: value.target.value})}} value={carouselUpdate.path_id || ''} name='pathID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>{'Tag* (add multiple tags by seperate with spacebar)'}</span>
                    <span className={`block text-xs`}>tag helps where is the items should show, add tag 'feature' for cover, add tag 'equipment' or 'skincare' for thumbnail.</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, tag: value.target.value})}} value={carouselUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>{'Public or Private* (display)'}</span>
                    <select onChange={(value) => {setCarouselUpdate({...carouselUpdate, display: value.target.value == 'PUBLI' ? true : false})}} value={carouselUpdate.display == true ? 'PUBLI' : 'PRIVA'} name='pageRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"PUBLI"}>Public</option>
                        <option value={"PRIVA"}>Private</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleAdd(carouselUpdate, modalInfo.type)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Add'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalEditComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{`Edit ${modalInfo.type ? String(modalInfo.type).toUpperCase() : 'item'}`}</span>
            <span className='font-bold'>{`Just edit only fields that you want to change for menu ID: ${modalInfo.id} (${modalInfo.name})`}</span>
            <div>
            <div>
                    <span className={`block`}>Item Name*</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, item_name: value.target.value})}} value={carouselUpdate.item_name || ''} name='itemName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Title*</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, title: value.target.value})}} value={carouselUpdate.title || ''} name='title' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Description</span>
                    <textarea onChange={(value) => {setCarouselUpdate({...carouselUpdate, description: value.target.value})}} value={carouselUpdate.description || ''} name='description' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Image ID*</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, img_id: value.target.value})}} value={carouselUpdate.img_id || ''} name='imgID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Path ID</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, path_id: value.target.value})}} value={carouselUpdate.path_id || ''} name='pathID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>{'Tag* (add multiple tags by seperate with spacebar)'}</span>
                    <span className={`block text-xs`}>tag helps where is the items should show, add tag 'feature' for cover, add tag 'equipment' or 'skincare' for thumbnail.</span>
                    <input onChange={(value) => {setCarouselUpdate({...carouselUpdate, tag: value.target.value})}} value={carouselUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>{'Public or Private* (display)'}</span>
                    <select onChange={(value) => {setCarouselUpdate({...carouselUpdate, display: value.target.value == 'PUBLI' ? true : false})}} value={carouselUpdate.display == true ? 'PUBLI' : 'PRIVA'} name='pageRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"PUBLI"}>Public</option>
                        <option value={"PRIVA"}>Private</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleUpdate(Number(modalInfo.id), modalInfo.name, carouselUpdate, modalInfo.type)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Edit'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalDeleteComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'DELETE'}</span>
            <span className='font-bold'>{`Are you sure to ${modalInfo.type ? String(modalInfo.type).toLowerCase() : 'item'} as item ID: ${modalInfo.id} (${modalInfo.name})?`}</span>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleDelete(Number(modalInfo.id), modalInfo.name, modalInfo.type)} className='border-2 border-slate-400 bg-red-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-red-600'>{'YES'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'NO'}</button>
            </div>
        </div>
    )}

    const handleClick = async (mode: string, info: any, type: string) => {
        // await setModalInfo({});
        await setCarouselUpdate({});
        if (mode == "ADD") {
            await setModalInfo({type: type});
        } else if (mode == "EDIT") {
            await setCarouselUpdate({...info.data});
            await setModalInfo({id: info.id, name: info.name, data: info.data, type: type});
        } else if (mode == 'DELETE') {
            await setModalInfo({id: info.id, name: info.name, type: type});
        }
        await setIsShowModal(false);
        await setModalMode(mode);
        await setIsShowModal(true);
    }

    const handleDelete = async (itemID: number, name: string, type: string) => {
        if (type == 'COVER') {
            await deleteCoverCarousel(itemID)
            .then((x) => alert(`Delete cover as item ID ${itemID} (${name}) successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to delete cover. Please check in console log`));

        } else if (type == 'THUMBNAIL') {
            await deleteThumbnailCarousel(itemID)
            .then((x) => alert(`Delete thumbnail as item ID ${itemID} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to delete thumbnail. Please check in console log`));
        }
    }

    const handleAdd = async (data: any, type: string) => {
        if (!data.item_name || !data.title || !data.img_id || !data.tag) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.item_name).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Item name only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        if (!data.display) {
            data.display = "PUBLI"
        }
        if (type == 'COVER') {
            await addCoverCarousel(data)
            .then((x) => alert(`Add cover successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to add cover. Please check in console log`));

        } else if (type == 'THUMBNAIL') {
            await addThumbnailCarousel(data)
            .then((x) => alert(`Add thumbnail successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to add thumbnail. Please check in console log`));
        }
    }

    const handleUpdate = async (itemID: number, name: string, data: any, type: string) => {
        if (!data.item_name || !data.title || !data.img_id || !data.tag) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.item_name).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Item name only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        if (!data.display) {
            data.display = "PUBLI"
        }
        let updateData = {};
        Object.keys(data).forEach(key => {
            let tempData = {}
            if (modalInfo.data[key] != data[key]) {
                tempData = {[key] : data[key]}
            }
            updateData = {...updateData, ...tempData}
        })
        if (type == 'COVER') {
            await updateCoverCarousel(itemID, data)
            .then((x) => alert(`Update cover as item ID ${itemID} (${name}) successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update cover. Please check in console log`));

        } else if (type == 'THUMBNAIL') {
            await updateThumbnailCarousel(itemID, data)
            .then((x) => alert(`Update thumbnail as item ID ${itemID} successful`))
            .then((x) => setIsShowModal(false))
            .then((x) => window.location.reload())
            .catch((err) => alert(`Error to update thumbnail. Please check in console log`));
        }
    }

    useEffect(() => {
        getAllCoverCarousel().then((res) => setCoverData(res as Array<carouselCoverType>)).then(x => setIsReadyCover(true));
        getAllThumbnailCarousel().then((res) => setThumbData(res as Array<carouselThumbnailType>)).then(x => setIsReadyThumb(true));
    }, [])

    return (
        <div>
            <Modal 
            component={modalMode == 'ADD' ? modalAddComp : modalMode == 'EDIT' ? modalEditComp : modalMode == 'DELETE' ? modalDeleteComp : modalDefaultComp} 
            isShow={isShowModal} />
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'Carousel'} isUnderline={false}/>
                    {/* COVER CAROUSEL */}
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Cover Carousel</span>
                        {isReadyCover ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {}, "COVER")} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Cover</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Item ID</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Title</th>
                                        <th className='text-center'>Description</th>
                                        <th className='text-center'>Image ID</th>
                                        <th className='text-center'>Path ID</th>
                                        <th className='text-center'>Tag</th>
                                        <th className='text-center'>Display</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        coverData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.item_id}</td>
                                                    <td className='text-center'>{item.item_name}</td>
                                                    <td className='text-center'>{item.title}</td>
                                                    <td className='text-center'>{item.description}</td>
                                                    <td className='text-center'>{item.img_id}</td>
                                                    <td className='text-center'>{item.path_id}</td>
                                                    <td className='text-center'>{item.tag}</td>
                                                    <td className='text-center'>{item.display ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center flex flex-nowrap'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.item_id, name: item.item_name, data: item}, "COVER")} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.item_id, name: item.item_name}, "COVER")} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { coverData.length <= 0 && isReadyCover ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                    {/* THUMBNAIL CAROUSEL */}
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Thumbnail Carousel</span>
                        {isReadyThumb ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {}, "THUMBNAIL")} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Thumbnail</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                    <th className='text-center'>Item ID</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Title</th>
                                        <th className='text-center'>Description</th>
                                        <th className='text-center'>Image ID</th>
                                        <th className='text-center'>Path ID</th>
                                        <th className='text-center'>Tag</th>
                                        <th className='text-center'>Display</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        thumbData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.item_id}</td>
                                                    <td className='text-center'>{item.item_name}</td>
                                                    <td className='text-center'>{item.title}</td>
                                                    <td className='text-center'>{item.description}</td>
                                                    <td className='text-center'>{item.img_id}</td>
                                                    <td className='text-center'>{item.path_id}</td>
                                                    <td className='text-center'>{item.tag}</td>
                                                    <td className='text-center'>{item.display ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center flex flex-nowrap'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.item_id, name: item.item_name, data: item}, "THUMBNAIL")} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.item_id, name: item.item_name}, "THUMBNAIL")} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { thumbData.length <= 0 && isReadyThumb ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CarouselConfig;
