import Home from './pages/home/home.page';
import Preferences from './pages/preferences/preferences.page';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
  } from "react-router-dom";
import Navbar from './components/navbar/navbar';
import ViewerNavigator from './pages/viewerNavigator/viewerNavigator.page';
import Notfound from './pages/notfound/notfound.page';
import Login from './pages/login/login.page';
import ForgetPassword from './pages/forgetPassword/forgetPassword.page';
import ResetPassword from './pages/resetPassword/resetPassword.page';

function Navigation () {
    return (
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/admin/login" element={<Login/>} />
                {/* <Route path="/admin/login/forget" element={<ForgetPassword/>} />
                <Route path="/admin/login/reset/:info" element={<ResetPassword/>} /> */}
                <Route path="/admin/preferences/*" element={<Preferences/>} />
                <Route path="/:tag/:slug" element={<ViewerNavigator/>}  />
                <Route path="/notfound" element={<Notfound/>} />
                <Route path="*" element={<Notfound/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Navigation;
