import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Header from '../../components/header/header';
import CarouselConfig from '../carouselConfig/carouselConfig.page';
import EtcConfig from '../etcConfig/etcConfig.page';
import ImageConfig from '../imageConfig/imageConfig.page';
import MenuConfig from '../menuConfig/menuConfig.page';
import PageConfig from '../pageConfig/pageConfig.page';
import Styles from './preferences.page.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faBars } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { logout } from '../../services/auth.service';
import PageDataConfig from '../pageDataConfig/pageDataConfig.page';

function Preferences () {
    const [menuClicked, setMenuClicked] = useState<string>('');
    const [user, setUser] = useState<string>('guys');
    const [isHideMenu, setIsHideMenu] = useState<boolean>(false);

    useEffect(() => {
        document.title = 'BiotechSG | Preferences';
      }, []);

    const nav = useNavigate();

    const handleGoToTop = () => {
        window.scrollTo(0,0)
    }

    const handleClick = (path: string) => {
        if (path == "hide") {
            setIsHideMenu(true);
            return;
        } else if (path == "show") {
            setIsHideMenu(false);
            return;
        } else if (path == "logout") {
            logout();
            return;
        }
        nav(path);
        setMenuClicked(path);
    }

    const PreferenceGeneralComp = () => {
        return (
            <div className={`bg-gray-100 h-full`}>
                <Header title='General' isUnderline={false}/>
                <div className={`flex h-full justify-center items-center`}>
                    <span className={`bg-white rounded-xl p-6 drop-shadow-sm font-bold`}>{`Hello ${user}!`}</span>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if ((localStorage.getItem('exp') || '0') == '0' ) {
            nav("/admin/login");
        }
        setUser((localStorage.getItem('username') || 'guys').replace(/[^a-zA-Z0-9\-_]/gm, ''));
    }, [])

    useEffect(() => {
        const href = window.location.href.split('/');
        setMenuClicked(href[href.length-1])
    }, [])

    return (
        <div className={`w-full h-full`}>
            <div className={`pt-20`}>
                <Header title='Preferences'/>
                <div className={`${Styles.pageContainer} flex flex-row w-100 flex-wrap border-t pt-6`}>
                    <div className={`flex-initial ml-6 mr-6 ${Styles.menuContainer} ${isHideMenu ? 'hidden' : ''}`}>
                        <div onClick={() => handleClick('general')} className={`drop-shadow flex justify-center items-center h-16 ${menuClicked == 'general' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} rounded mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'General'}</span>
                        </div>
                        <div onClick={() => handleClick('menu')} className={`drop-shadow flex justify-center items-center h-16 rounded ${menuClicked == 'menu' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Menu'}</span>
                        </div>
                        <div onClick={() => handleClick('page')} className={`drop-shadow flex justify-center items-center h-16 rounded ${menuClicked == 'page' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Pages'}</span>
                        </div>
                        <div onClick={() => handleClick('pagedata')} className={`drop-shadow flex justify-center items-center h-16 rounded ${menuClicked == 'pagedata' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Page Data'}</span>
                        </div>
                        <div onClick={() => handleClick('carousel')} className={`drop-shadow flex justify-center items-center h-16 rounded ${menuClicked == 'carousel' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Carousel'}</span>
                        </div>
                        <div onClick={() => handleClick('image')} className={`drop-shadow flex justify-center items-center h-16 rounded ${menuClicked == 'image' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Images'}</span>
                        </div>
                        <div onClick={() => handleClick('advance')} className={`drop-shadow flex justify-center items-center h-16 rounded ${menuClicked == 'advance' ? 'rounded bg-gray-400' : 'rounded bg-gray-100'} mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Advances'}</span>
                        </div>
                        <div onClick={() => handleClick('logout')} className={`flex justify-center items-center h-16 rounded mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'Logout'}</span>
                        </div>
                        <div onClick={() => handleClick('hide')} className={`flex justify-center items-center h-16 rounded mb-2 hover:bg-gray-200 cursor-pointer`}>
                            <span className={`font-bold`}>{'<< Hide Menu'}</span>
                        </div>
                    </div>
                    <div className={`flex-1 ${Styles.contentContainer}`}>
                        <Routes>
                            <Route path='/' element={<PreferenceGeneralComp/>}/>
                            <Route path='/menu' element={<MenuConfig/>}/>
                            <Route path='/page' element={<PageConfig/>}/>
                            <Route path='/carousel' element={<CarouselConfig/>}/>
                            <Route path='/image' element={<ImageConfig/>}/>
                            <Route path='/advance' element={<EtcConfig/>}/>
                            <Route path='/pagedata' element={<PageDataConfig/>}/>
                            <Route path='*' element={<PreferenceGeneralComp/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
            { isMobile ?
                <div className={`${Styles.goToTop} mr-4 mb-4 rounded border-2 py-2 px-4`} onClick={handleGoToTop}>
                    <FontAwesomeIcon icon={faAnglesUp} />
                </div> : null
            }
            <div className={`${Styles.menuBttn} cursor-pointer mr-4 mb-4 rounded border-2 py-2 px-4 ${isHideMenu ? 'block' : 'hidden'}`} onClick={() => handleClick('show')}>
                <FontAwesomeIcon icon={faBars} />
            </div>
        </div>
    )
}

export default Preferences;
