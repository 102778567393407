import React, { useEffect } from 'react';
import Navigation from './Navigation';
import './App.css';
import Navbar from './components/navbar/navbar';
import { initial } from './services/initial.service';

function App() {
  useEffect(() => {
    initial();
  }, [])
  return (
    <div className="App">
      <header>
      </header>
      <Navigation/>
    </div>
  );
}

export default App;
