import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { getPageData } from '../../services/viewer.service';
import { pageDataType } from '../../types/pageData.types';
import { viewerType } from '../../types/viewer.types';
import Styles from './iframeViewer.page.module.css';

function IFrameViewer (props: viewerType) {
    const [pageData, setPageData] = useState<Array<pageDataType>>([]);

    useEffect(() => {
        document.title = 'BiotechSG | ' + `${pageData.find((item) => item.data_name.includes('title'))?.value}`;
      }, []);

    useEffect(() => {
        try {
            getPageData(props.pageSlug)
            .then((res) => res as any)
            .then((jsonres: Array<pageDataType>) => {
                setPageData(jsonres);
            });
        } catch(err) {
            console.log(err);
        }
    }, [props])

    return (
        <div>
            <div className={`${Styles.pageContainer} mb-5`}>
            <div className={`${Styles.pageContent}`}>
                <Header title={`${pageData.find((item) => item.data_name.includes('title'))?.value}`}/>
                <div className={`${Styles.iframeContainer} mt-5`}>
                    <iframe 
                    src={pageData.find((item) => item.data_type.includes('URL'))?.value || ''}
                    width={'100%'}
                    height={'100%'}
                    />
                </div>
            </div>
            </div>
            <Footer/>
        </div>
    )
}

export default IFrameViewer;
