import { useEffect, useState } from 'react';
import { modalType } from '../../types/modal.types';
import Styles from './modal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

function Modal (props: modalType) {
    const [Component, setComponent] = useState<any>(props.component);
    const [isShow, setIsShow] = useState<boolean>(props.isShow);

    useEffect(() => {
        setComponent(props.component)
    }, [props.component])

    useEffect(() => {
        setIsShow(props.isShow)
    }, [props])

    return (
        <div className={`${isShow ? '' : 'hidden'}`}>
            <div className={`${Styles.container}`}>
                <div className={`${Styles.content} drop-shadow-lg`}>
                    <div onClick={() => setIsShow(!isShow)} className={`${Styles.closeIcon } cursor-pointer`}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                    {
                        Component ? Component : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Modal