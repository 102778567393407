import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import { getPageInfo } from '../../services/viewer.service';
import { pageType } from '../../types/page.types';
import IFrameViewer from '../iframeViewer/iframeViewer.page';
import MarkdownViewer from '../markdownViewer/markdownViewer.page';
import PDFViewer from '../pdfViewer/pdfViewer.page';
import Styles from './viewerNavigator.page.module.css';

function ViewerNavigator () {
    const {tag, slug} = useParams();

    const [pages, setPages] = useState<Array<pageType>>([]);
    const [isReady, setIsReady] = useState<boolean>(false);

    const tag_query = (tag || '').replace(/[^!a-zA-z_\-%]/gm, '');
    const slug_query = (slug || '').replace(/[^!a-zA-z_\-%]/gm, '');

    useEffect(() => {
        getPageInfo(tag_query, slug_query)
            .then((res) => res as any)
            .then((data_json) => setPages(data_json))
            .then(() => setIsReady(true));
    }, [tag_query, slug_query])

    return (
        <div>
            {isReady ? (pages.length <= 0 ? <Navigate to={'/notfound'} /> : 
            pages[0].page_type == 'MARKDOWN' ? <MarkdownViewer pageSlug={pages[0].page_slug}/> :
            pages[0].page_type == 'IFRAME' ? <IFrameViewer pageSlug={pages[0].page_slug}/> :
            pages[0].page_type == 'PDF' ? <PDFViewer pageSlug={pages[0].page_slug}/> :
            <Navigate to={'/notfound'} />
            ) : null}
        </div>
    )
}

export default ViewerNavigator;
