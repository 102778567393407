import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { pageType } from '../../types/page.types';
import Styles from './pageConfig.page.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { addPage, deletePage, getAllPage, updatePage } from '../../services/admin.service';
import Modal from '../../components/modal/modal';

function PageConfig () {
    const [pageData, setPageData] = useState<Array<pageType>>([]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<string>('');
    const [isReadyPage, setIsReadyPage] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<any>({});
    const [pageUpdate, setPageUpdate] = useState<any>({});

    const modalDefaultComp = () => { return (
        <div>
            <p>{'Nothing to show'}</p>
        </div>
    )}

    const modalAddComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'ADD'}</span>
            <div>
                <div>
                    <span className={`block`}>Page Slug*</span>
                    <input onChange={(value) => {setPageUpdate({...pageUpdate, page_slug: value.target.value})}} value={pageUpdate.page_slug || ''} name='pageSlug' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Public or Private*</span>
                    <select onChange={(value) => {setPageUpdate({...pageUpdate, page_role: value.target.value})}} value={pageUpdate.page_role || 'PUBLI'} name='pageRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"PUBLI"}>Public</option>
                        <option value={"PRIVA"}>Private</option>
                    </select>
                </div>
                <div>
                    <span className={`block`}>{'Tag (add multiple tags by seperate with spacebar)'}</span>
                    <input onChange={(value) => {setPageUpdate({...pageUpdate, tag: value.target.value})}} value={pageUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Page Type*</span>
                    <select onChange={(value) => {setPageUpdate({...pageUpdate, page_type: value.target.value})}} value={pageUpdate.page_type || 'MARKDOWN'} name='pageType' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"MARKDOWN"}>Markdown</option>
                        <option value={"PDF"}>PDF</option>
                        <option value={"IFRAME"}>IFrame</option>
                        <option value={"CUSTOM"}>Custom</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleAdd(pageUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Add'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalEditComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'Edit'}</span>
            <span className='font-bold'>{`Just edit only fields that you want to change for menu ID: ${modalInfo.id} (${modalInfo.name})`}</span>
            <div>
                <div>
                    <span className={`block`}>Page Slug*</span>
                    <input onChange={(value) => {setPageUpdate({...pageUpdate, page_slug: value.target.value})}} value={pageUpdate.page_slug || ''} name='pageSlug' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Public or Private*</span>
                    <select onChange={(value) => {setPageUpdate({...pageUpdate, page_role: value.target.value})}} value={pageUpdate.page_role || ''} name='pageRole' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"PUBLI"}>Public</option>
                        <option value={"PRIVA"}>Private</option>
                    </select>
                </div>
                <div>
                    <span className={`block`}>{'Tag (add multiple tags by seperate with spacebar)'}</span>
                    <input onChange={(value) => {setPageUpdate({...pageUpdate, tag: value.target.value})}} value={pageUpdate.tag || ''} name='tag' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Page Type*</span>
                    <select onChange={(value) => {setPageUpdate({...pageUpdate, page_type: value.target.value})}} value={pageUpdate.page_type || 'MARKDOWN'} name='pageType' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={"MARKDOWN"}>Markdown</option>
                        <option value={"PDF"}>PDF</option>
                        <option value={"IFRAME"}>IFrame</option>
                        <option value={"CUSTOM"}>Custom</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleUpdate(Number(modalInfo.id), pageUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Edit'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalDeleteComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'DELETE'}</span>
            <span className='font-bold'>{`Are you sure to delete page as page ID: ${modalInfo.id} (${modalInfo.name})?`}</span>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleDelete(Number(modalInfo.id), modalInfo.name)} className='border-2 border-slate-400 bg-red-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-red-600'>{'YES'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'NO'}</button>
            </div>
        </div>
    )}

    const handleClick = async (mode: string, info: any) => {
        // await setModalInfo({});
        await setPageUpdate({});
        if (mode == "ADD") {
            await setModalInfo({});
        } else if (mode == "EDIT") {
            await setPageUpdate({...info.data});
            await setModalInfo({id: info.id, name: info.name, data: info.data});
        } else if (mode == 'DELETE') {
            await setModalInfo({id: info.id, name: info.name});
        }
        await setIsShowModal(false);
        await setModalMode(mode);
        await setIsShowModal(true);
    }

    const handleDelete = async (pageID: number, pageSlug: string) => {
        await deletePage(pageID)
        .then((x) => alert(`Delete page as page ID ${pageID} (${pageSlug}) successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to delete page. Please check in console log`));
    }

    const handleAdd = async (data: any) => {
        if (!data.page_slug) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.page_slug).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Page slug only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        if (!data.page_role) {
            data.page_role = "PUBLI"
        }
        if (!data.page_type) {
            data.page_type = "MARKDOWN"
        }
        await addPage(data)
        .then((x) => alert(`Add page successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to add page. Please check in console log`));
    }

    const handleUpdate = async (pageID: number, data: any) => {
        if (!data.page_slug) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.page_slug).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Page slug only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        let updateData = {};
        Object.keys(data).forEach(key => {
            let tempData = {}
            if (modalInfo.data[key] != data[key]) {
                tempData = {[key] : data[key]}
            }
            updateData = {...updateData, ...tempData}
        })
        await updatePage(pageID, updateData as any)
        .then((x) => alert(`Update page as page ID ${pageID} (${data.page_slug}) successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to update page. Please check in console log`));
    }

    useEffect(() => {
        getAllPage().then((res) => setPageData(res as Array<pageType>)).then((x) => setIsReadyPage(true));
    }, [])

    return (
        <div>
            <Modal 
            component={modalMode == 'ADD' ? modalAddComp : modalMode == 'EDIT' ? modalEditComp : modalMode == 'DELETE' ? modalDeleteComp : modalDefaultComp} 
            isShow={isShowModal} />
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'Pages'} isUnderline={false}/>
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Pages</span>
                        {isReadyPage ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Page</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Page ID</th>
                                        <th className='text-center'>Slug</th>
                                        <th className='text-center'>Public</th>
                                        <th className='text-center'>Type</th>
                                        <th className='text-center'>Tag</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pageData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.page_id}</td>
                                                    <td className='text-center'>{item.page_slug}</td>
                                                    <td className='text-center'>{item.page_role == 'PUBLI' ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center'>{item.page_type}</td>
                                                    <td className='text-center'>{item.tag}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.page_id, name: item.page_slug, data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.page_id, name: item.page_slug})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { pageData.length <= 0 && isReadyPage ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PageConfig;
