import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Modal from '../../components/modal/modal';
import { addMenu, addPageData, deleteMenu, deletePageData, getAllMenu, getPageDataByPageId, updateMenu, updatePageData } from '../../services/admin.service';
import Styles from './pageDataConfig.page.module.css';
import { pageDataType } from '../../types/pageData.types';

function PageDataConfig () {
    const [pageData, setPageData] = useState<Array<pageDataType>>([]);
    const [pageIDSelector, setPageIDSelector] = useState<number>(1);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<string>('');
    const [isReadyMenu, setIsReadyMenu] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<any>({});
    const [pageDataUpdate, setPageDataUpdate] = useState<any>({});

    const modalDefaultComp = () => { return (
        <div>
            <p>{'Nothing to show'}</p>
        </div>
    )}

    const modalAddComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'ADD'}</span>
            <div>
                <div>
                    <span className={`block`}>Page ID*</span>
                    <span className='block text-xs'>Data will shown only in specific page by page ID</span>
                    <input onChange={(value) => {setPageDataUpdate({...pageDataUpdate, page_id: value.target.value})}} value={pageDataUpdate?.page_id || ''} name='pageID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Data Type*</span>
                    <select onChange={(value) => {setPageDataUpdate({...pageDataUpdate, data_type: value.target.value})}} value={pageDataUpdate?.data_type || 'TEXT'} name='dataType' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={'TEXT'}>Text</option>
                        <option value={'IMAGE'}>Image</option>
                        <option value={'MARKDOWN'}>Markdown</option>
                        <option value={'PDF'}>PDF</option>
                        <option value={'IFRAME'}>IFrame</option>
                    </select>
                </div>
                <div>
                    <span className={`block`}>Value*</span>
                    <textarea onChange={(value) => {setPageDataUpdate({...pageDataUpdate, value: value.target.value})}} value={pageDataUpdate?.value || ''} name='value' className={`rounded-lg border-2 border-slate-200 w-full h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Data Name*</span>
                    <input onChange={(value) => {setPageDataUpdate({...pageDataUpdate, data_name: value.target.value})}} value={pageDataUpdate?.data_name || ''} name='value' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div><span className='text-xs'>Data Type 'Text' with Data Name end with the word 'title' will using for title</span></div>
                <div><span className='text-xs'>For content in each page, please select Data Type 'Markdown', 'PDF', or 'IFrame'</span></div>
                <div><span className='text-xs'>Value depends on Data Type e.g. 'Image' using image ID, 'PDF' using file ID, 'IFrame' using url</span></div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleAdd(pageDataUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Add'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalEditComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'Edit'}</span>
            <span className='font-bold'>{`Just edit only fields that you want to change for data ID: ${modalInfo.id} (${modalInfo.name})`}</span>
            <div>
                <div>
                    <span className={`block`}>Page ID*</span>
                    <span className='block text-xs'>Data will shown only in specific page by page ID</span>
                    <input onChange={(value) => {setPageDataUpdate({...pageDataUpdate, page_id: value.target.value})}} value={pageDataUpdate?.page_id || ''} name='pageID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Data Type*</span>
                    <select onChange={(value) => {setPageDataUpdate({...pageDataUpdate, data_type: value.target.value})}} value={pageDataUpdate?.data_type || 'TEXT'} name='dataType' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={'TEXT'}>Text</option>
                        <option value={'IMAGE'}>Image</option>
                        <option value={'MARKDOWN'}>Markdown</option>
                        <option value={'PDF'}>PDF</option>
                        <option value={'IFRAME'}>IFrame</option>
                    </select>
                </div>
                <div>
                    <span className={`block`}>Value*</span>
                    <textarea onChange={(value) => {setPageDataUpdate({...pageDataUpdate, value: value.target.value})}} value={pageDataUpdate?.value || ''} name='value' className={`rounded-lg border-2 border-slate-200 w-full h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Data Name*</span>
                    <input onChange={(value) => {setPageDataUpdate({...pageDataUpdate, data_name: value.target.value})}} value={pageDataUpdate?.data_name || ''} name='value' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div><span className='text-xs'>Data Type 'Text' with Data Name end with the word 'title' will using for title</span></div>
                <div><span className='text-xs'>For content in each page, please select Data Type 'Markdown', 'PDF', or 'IFrame'</span></div>
                <div><span className='text-xs'>Value depends on Data Type e.g. 'Image' using image ID, 'PDF' using file ID, 'IFrame' using url</span></div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleUpdate(Number(modalInfo.id), pageDataUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Edit'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalDeleteComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'DELETE'}</span>
            <span className='font-bold'>{`Are you sure to delete page data as data ID: ${modalInfo.id} (${modalInfo.name})?`}</span>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleDelete(Number(modalInfo.id), modalInfo.name)} className='border-2 border-slate-400 bg-red-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-red-600'>{'YES'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'NO'}</button>
            </div>
        </div>
    )}

    const handleClick = async (mode: string, info: any) => {
        // await setModalInfo({});
        await setPageDataUpdate({});
        if (mode == "ADD") {
            await setModalInfo({});
        } else if (mode == "EDIT") {
            await setPageDataUpdate({...info.data});
            await setModalInfo({id: info.id, name: info.name, data: info.data});
        } else if (mode == 'DELETE') {
            await setModalInfo({id: info.id, name: info.name});
        }
        await setIsShowModal(false);
        await setModalMode(mode);
        await setIsShowModal(true);
    }

    const handleDelete = async (dataID: number, dataName: string) => {
        await deletePageData(dataID)
        .then((x) => alert(`Delete page data as data ID ${dataID} (${dataName}) successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to delete page data. Please check in console log`));
    }

    const handleAdd = async (data: any) => {
        data.data_type = data.data_type || 'TEXT';
        if (!data.page_id || !data.data_type || !data.value || !data.data_name) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.data_name).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Menu name only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        await addPageData(data)
        .then((x) => alert(`Add page data successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to add page data. Please check in console log`));
    }

    const handleUpdate = async (menuID: number, data: any) => {
        data.data_type = data.data_type || 'TEXT';
        if (!data.page_id || !data.data_type || !data.value || !data.data_name) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.data_name).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Menu name only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        let updateData = {};
        Object.keys(data).forEach(key => {
            let tempData = {}
            if (modalInfo.data[key] != data[key]) {
                tempData = {[key] : data[key]}
            }
            updateData = {...updateData, ...tempData}
        })
        await updatePageData(menuID, updateData as any)
        .then((x) => alert(`Update page data successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to update page data. Please check in console log`));
    }

    useEffect(() => {
        getPageDataByPageId(pageIDSelector || 1).then((res) => setPageData(res as Array<pageDataType>)).then((x) => setIsReadyMenu(true));
    }, [pageIDSelector])

    return (
        <div>
            <Modal 
            component={modalMode == 'ADD' ? modalAddComp : modalMode == 'EDIT' ? modalEditComp : modalMode == 'DELETE' ? modalDeleteComp : modalDefaultComp} 
            isShow={isShowModal} />
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'Page Data'} isUnderline={false}/>
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>
                            Manage Page Data
                            <span className={`ml-4`}>Page ID <input onChange={(value) => {setPageIDSelector(Number(value.target.value))}} value={pageIDSelector} name='pageID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-12 h-10 px-2 ml-2`}/></span>
                        </span>
                        {isReadyMenu ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Page Data</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Data ID</th>
                                        <th className='text-center'>Page ID</th>
                                        <th className='text-center'>Data Type</th>
                                        <th className='text-center'>Value</th>
                                        <th className='text-center'>Data Name</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pageData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.data_id}</td>
                                                    <td className='text-center'>{item.page_id}</td>
                                                    <td className='text-center'>{item.data_type}</td>
                                                    <td className='text-center'>{item.value}</td>
                                                    <td className='text-center'>{item.data_name}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.data_id, name: item.data_name, data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.data_id, name: item.data_name})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { pageData.length <= 0 && isReadyMenu ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PageDataConfig;
