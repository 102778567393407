import axios from "axios";
import { carouselCoverType, carouselThumbnailType } from "../types/carousel.types";
import { ImageType } from "../types/image.types";
import { menuType } from "../types/menu.types";
import { pageType } from "../types/page.types";
import { pageDataType } from "../types/pageData.types";
import { PathType } from "../types/reference.types";
import { UserType } from "../types/user.types";


// IMAGE
export async function getAllImgData() {
        return new Promise((resolve, reject) => {
            try {
            axios.get(`${process.env.REACT_APP_HOST}/util/getallimgdata`, {withCredentials: true})
            .then((res) => res.data)
            .then((jsonres: Array<ImageType>) => {
                resolve(jsonres);
            })
            .catch((err) => console.log(err));
            } catch(err) {
                console.log(err);
                reject([]);
            }
        });
}

export async function uploadImage(image: any) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/img/add`, image, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateImageInfo(imgID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/img/update/${String(imgID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deleteImage(imgID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/util/img/delete/${String(imgID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

// CAROUSAL
export async function getAllCoverCarousel() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/carousel/coverall`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<carouselCoverType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function getAllThumbnailCarousel() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/carousel/thumbnailall`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<carouselThumbnailType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function deleteCoverCarousel(itemID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/carousel/cover/delete/${String(itemID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function addCoverCarousel(data: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/carousel/cover/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateCoverCarousel(itemID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/carousel/cover/update/${String(itemID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deleteThumbnailCarousel(itemID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/carousel/thumbnail/delete/${String(itemID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function addThumbnailCarousel(data: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/carousel/thumbnail/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateThumbnailCarousel(itemID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/carousel/thumbnail/update/${String(itemID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

// MENU
export async function getAllMenu() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/menu/all`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<menuType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function deleteMenu(menuID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/menu/delete/${String(menuID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function addMenu(data: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/menu/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateMenu(menuID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/menu/update/${String(menuID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

// PAGE
export async function getAllPage() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/page/all`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<pageType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function deletePage(pageID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/page/delete/${String(pageID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function addPage(data: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/page/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updatePage(pageID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/page/update/${String(pageID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

//PAGE DATA
export async function getPageDataByPageId(pageID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/page/data/pageid/${pageID}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<pageDataType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function addPageData(data: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/page/data/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deletePageData(dataID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/page/data/delete/${String(dataID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updatePageData(dataID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/page/data/update/${String(dataID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

//USER
export async function getAllUser() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/auth/user/all`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<UserType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function addUser(data: UserType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/auth/user/create`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateUser(userID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/auth/user/update/${String(userID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deleteUser(userID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/auth/user/delete/${String(userID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function checkIsUsedUsername(username: string) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/auth/user/checkusername/${String(username)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

//PATH
export async function getAllPath() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/util/path/all`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<PathType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function addPath(data: UserType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/path/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updatePath(pathID: number, newData: menuType) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/path/update/${String(pathID)}`, {...newData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deletePath(pathID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/util/path/delete/${String(pathID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

//FILE
export async function getAllFile() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/util/file/all`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<PathType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function uploadFile(item: any) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/file/add`, item, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateFileInfo(fileID: number, updateData: any) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/file/update/${String(fileID)}`, {...updateData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deleteFile(fileID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/util/file/delete/${String(fileID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

//REF
export async function getAllRef() {
    return new Promise((resolve, reject) => {
        try {
        axios(`${process.env.REACT_APP_HOST}/util/ref/all`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<PathType>) => {
            resolve(jsonres);
        })
        } catch(err) {
            console.log(err);
            reject([]);
        }
    });
}

export async function addRef(data: any) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/ref/add`, {...data}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function updateRef(refID: number, updateData: any) {
    return new Promise((resolve, reject) => {
        try {
        axios.post(`${process.env.REACT_APP_HOST}/util/ref/update/${String(refID)}`, {...updateData}, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}

export async function deleteRef(refID: number) {
    return new Promise((resolve, reject) => {
        try {
        axios.get(`${process.env.REACT_APP_HOST}/util/ref/delete/${String(refID)}`, {withCredentials: true})
        .then((res) => res.data)
        .then((jsonres: Array<any>) => {
            resolve(jsonres);
        })
        .catch((err) => {
            throw err;
        })
        } catch(err) {
            console.log(err);
            reject([{err}]);
        }
    });
}