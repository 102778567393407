import axios from "axios";
import ms from "ms";

export function login(username: string, password: string) {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`${process.env.REACT_APP_HOST}/auth/generatetoken`, {
                username: username,
                password: password
            }, {withCredentials: true})
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem('exp', ms(res.data.exp)+ new Date().getTime());
                    resolve("OK");
                }
            }, (err) => {
                if (err.response.status === 401) {
                    reject("INVALID");
                } else if (err.response.status === 423) {
                    reject("LOCKED");
                } else {
                    reject("ERROR");
                }
            });
        } catch(err) {
            console.log("ERROR FETCHING: " + err);
            reject("ERROR");
        }
    })
}

export function logout() {
    return new Promise((resolve, reject) => {
        try {
            axios(`${process.env.REACT_APP_HOST}/auth/logout`, {withCredentials: true, method: 'get'})
            .then((res) => {
                if (res.status == 200) {
                    localStorage.setItem('exp', '0');
                    window.location.reload();
                    resolve("OK");
                } else {
                    reject("ERROR STATUS: " + res.status);
                }
            })
        } catch (err) {
            console.log("ERROR LOGOUT: " + err);
            reject("ERROR");
        }
    })
}

export function forgetPass(username: string) {
    return new Promise((resolve, reject) => {
        resolve("OK");
        // try {
        //     axios(`${process.env.REACT_APP_HOST}/auth/logout`, {withCredentials: true, method: 'get'})
        //     .then((res) => {
        //         if (res.status == 200) {
        //             localStorage.setItem('exp', '0');
        //             window.location.reload();
        //             resolve("OK");
        //         } else {
        //             reject("ERROR STATUS: " + res.status);
        //         }
        //     })
        // } catch (err) {
        //     console.log("ERROR LOGOUT: " + err);
        //     reject("ERROR");
        // }
    })
}

export function resetPass(username: string, token: string, tokentype: string, newpassword: string) {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`${process.env.REACT_APP_HOST}/auth/resetpass`, {username, token, tokentype, newpassword}, {withCredentials: true})
            .then((res) => res.data)
            .then((jsonres: Array<any>) => {
                resolve(jsonres);
            })
            .catch((err) => {
                throw err;
            })
        } catch (err) {
            console.log("ERROR RESET PASSWORD: " + err);
            reject([{err}]);
        }
    })
}
