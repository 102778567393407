import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { login } from '../../services/auth.service';
import Styles from './login.page.module.css';

function Login () {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [msg, setMsg] = useState<string>('');

    const nav = useNavigate();

    useEffect(() => {
        document.title = 'BiotechSG | Admin Login';
      }, []);

    const handleLogin = async () => {
        setMsg('');
        if (username.length <= 0 || password.length <= 0) {
            setMsg('Please fill in username and password');
            return;
        }
        login(username, password).then((res) => {
            localStorage.setItem('username', username);
            nav('/admin/preferences');
        }).catch((err) => {
            if (err == 'INVALID') {
                setMsg('Invalid username or password');
            } else if (err == 'LOCKED') {
                setMsg('User has been locked, please contact a web adminstrator to proceed.');
            } else {
                setMsg('An error has occur, please try again.');
            }
        })
    }

    const handleForgotPassword = () => {
        nav('/admin/login/forget');
    }

    return (
        <div>
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'BIO TECH INDUSTRIAL PORTAL LOGIN'}/>
                    <form className={`flex flex-1 flex-col justify-evenly`}>
                        <div>
                            <span className={`block`}>Username</span>
                            <input onChange={(value) => {setUsername(value.target.value)}} value={username} name='username' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                        </div>
                        <div>
                            <span className={`block`}>Password</span>
                            <input onChange={(value) => {setPassword(value.target.value)}} value={password} name='password' type={'password'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                        </div>
                        <span className={`text-red-500`}>{msg}</span>
                        <button type='button' onClick={() => handleLogin()} className={`rounded-lg border-2 border-slate-700 mt-4 h-10 cursor-pointer`}>Login</button>
                    </form>
                    <span>Forgot password? please contact administrator to reset password.</span>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Login;
