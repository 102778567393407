import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Styles from "./notfound.page.module.css";

function Notfound() {
  useEffect(() => {
    document.title = 'BiotechSG | Page not found';
  }, []);

  return (
    <div className={`${Styles.pageContainer}`}>
        <div className={`absolute top-0 left-0 bg-black w-screen h-20`}></div>
      <Header title="404 PAGE NOT FOUND" />
      <div className={"h-24 flex justify-center items-center"}>
        <Link
          className={
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          } to={"/"}
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
}

export default Notfound;
