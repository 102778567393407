import axios from "axios";

export async function fetchMenu() {
    return new Promise((resolve, reject) => {
        try {
            axios(`${process.env.REACT_APP_HOST}/menu`, {withCredentials: true})
            .then((res) => {resolve(res.data)});
        } catch (err) {
            console.log(err);
            resolve([])
        }
    })
}