import axios from "axios";

export async function getPageData(pageSlug: string) {
    return new Promise((resolve, reject) => {
        try {
            axios(`${process.env.REACT_APP_HOST}/page/data/${pageSlug}`, {withCredentials: true})
            .then((res) => resolve(res.data))
        } catch(err) {
            console.log(err);
            resolve([])
        }
    });

}

export async function getPageInfo(tag: string, slug: string) {
    return new Promise((resolve, reject) => {
        try {
            axios(`${process.env.REACT_APP_HOST}/page?tag=${tag}&slug=${slug}`, {withCredentials: true})
            .then((res) => resolve(res.data))
        } catch(err) {
            console.log(err);
            resolve([]);
        }
    });
}