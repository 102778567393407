import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Modal from '../../components/modal/modal';
import { addMenu, deleteMenu, getAllMenu, updateMenu } from '../../services/admin.service';
import { menuType } from '../../types/menu.types';
import Styles from './menuConfig.page.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

function MenuConfig () {
    const [menuData, setMenuData] = useState<Array<menuType>>([]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<string>('');
    const [isReadyMenu, setIsReadyMenu] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<any>({});
    const [menuUpdate, setMenuUpdate] = useState<any>({});

    const modalDefaultComp = () => { return (
        <div>
            <p>{'Nothing to show'}</p>
        </div>
    )}

    const modalAddComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'ADD'}</span>
            <div>
                <div>
                    <span className={`block`}>Name*</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, menu_name: value.target.value})}} value={menuUpdate?.menu_name || ''} name='menuName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Display Name*</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, menu_display: value.target.value})}} value={menuUpdate?.menu_display || ''} name='displayName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Parent Menu ID</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, parent_menu: value.target.value})}} value={menuUpdate?.parent_menu || ''} name='parentMenuID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Thumbnail ID</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, thumbnail_id: value.target.value})}} value={menuUpdate?.thumbnail_id || ''} name='thumbnailID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Show*</span>
                    <select onChange={(value) => {setMenuUpdate({...menuUpdate, is_show: value.target.value})}} value={Number(menuUpdate?.is_show) ? 1 : 0} name='show' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                </div>
                <div>
                    <span className={`block`}>Path ID</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, path_id: value.target.value})}} value={menuUpdate?.path_id || ''} name='pathID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleAdd(menuUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Add'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalEditComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'Edit'}</span>
            <span className='font-bold'>{`Just edit only fields that you want to change for menu ID: ${modalInfo.id} (${modalInfo.name})`}</span>
            <div>
                <div>
                    <span className={`block`}>Name</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, menu_name: value.target.value})}} value={menuUpdate.menu_name || ''} name='menuName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Display Name</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, menu_display: value.target.value})}} value={menuUpdate?.menu_display || ''} name='displayName' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Parent Menu ID</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, parent_menu: value.target.value})}} value={menuUpdate.parent_menu || ''} name='parentMenuID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Thumbnail ID</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, thumbnail_id: value.target.value})}} value={menuUpdate.thumbnail_id || ''} name='thumbnailID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
                <div>
                    <span className={`block`}>Show</span>
                    <select onChange={(value) => {setMenuUpdate({...menuUpdate, is_show: value.target.value})}} value={Number(menuUpdate.is_show) ? 1 : 0} name='show' className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                </div>
                <div>
                    <span className={`block`}>Path ID</span>
                    <input onChange={(value) => {setMenuUpdate({...menuUpdate, path_id: value.target.value})}} value={menuUpdate.path_id || ''} name='pathID' type={'text'} className={`rounded-lg border-2 border-slate-200 w-60 h-10 px-2`}/>
                </div>
            </div>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleUpdate(Number(modalInfo.id), menuUpdate)} className='border-2 border-slate-400 bg-blue-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-blue-600'>{'Edit'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'Cancel'}</button>
            </div>
        </div>
    )}

    const modalDeleteComp = () => { return (
        <div className='flex flex-col justify-center'>
            <span className='text-lg font-bold'>{'DELETE'}</span>
            <span className='font-bold'>{`Are you sure to delete menu as menu ID: ${modalInfo.id} (${modalInfo.name})?`}</span>
            <div className='flex flex-row justify-evenly'>
                <button onClick={() => handleDelete(Number(modalInfo.id), modalInfo.name)} className='border-2 border-slate-400 bg-red-500 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-red-600'>{'YES'}</button>
                <button onClick={() => setIsShowModal(false)} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300'>{'NO'}</button>
            </div>
        </div>
    )}

    const handleClick = async (mode: string, info: any) => {
        // await setModalInfo({});
        await setMenuUpdate({});
        if (mode == "ADD") {
            await setModalInfo({});
        } else if (mode == "EDIT") {
            await setMenuUpdate({...info.data});
            await setModalInfo({id: info.id, name: info.name, data: info.data});
        } else if (mode == 'DELETE') {
            await setModalInfo({id: info.id, name: info.name});
        }
        await setIsShowModal(false);
        await setModalMode(mode);
        await setIsShowModal(true);
    }

    const handleDelete = async (menuID: number, menuName: string) => {
        await deleteMenu(menuID)
        .then((x) => alert(`Delete menu as menu ID ${menuID} (${menuName}) successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to delete menu. Please check in console log`));
    }

    const handleAdd = async (data: any) => {
        data.is_show = Number(data.is_show) ? true : false;
        if (!data.menu_name || !data.menu_display) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.menu_name).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Menu name only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        await addMenu(data)
        .then((x) => alert(`Add menu successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to add menu. Please check in console log`));
    }

    const handleUpdate = async (menuID: number, data: any) => {
        data.is_show = Number(data.is_show) ? true : false;
        if (!data.menu_name || !data.menu_display) {
            alert('Required field cannot be empty (*)');
            return;
        }
        if (String(data.menu_name).replace(/[a-zA-Z0-9\-_]/gm, '').length > 0) {
            alert('Menu name only allow alphabet, number, underscore, and dash (e.g. spacebar not allowed)');
            return;
        }
        let updateData = {};
        Object.keys(data).forEach(key => {
            let tempData = {}
            if (modalInfo.data[key] != data[key]) {
                tempData = {[key] : data[key]}
            }
            updateData = {...updateData, ...tempData}
        })
        await updateMenu(menuID, updateData as any)
        .then((x) => alert(`Update menu successful`))
        .then((x) => setIsShowModal(false))
        .then((x) => window.location.reload())
        .catch((err) => alert(`Error to update menu. Please check in console log`));
    }

    useEffect(() => {
        getAllMenu().then((res) => setMenuData(res as Array<menuType>)).then((x) => setIsReadyMenu(true));
    }, [])

    return (
        <div>
            <Modal 
            component={modalMode == 'ADD' ? modalAddComp : modalMode == 'EDIT' ? modalEditComp : modalMode == 'DELETE' ? modalDeleteComp : modalDefaultComp} 
            isShow={isShowModal} />
            <div className={`${Styles.pageContainer} pb-5 bg-slate-200`}>
                <div className={`${Styles.pageContent} flex flex-col justify-around items-center pb-[40vh]`}>
                    <Header title={'Menu'} isUnderline={false}/>
                    <div className={`flex flex-col w-full`}>
                        <span className='font-bold text-lg'>Manage Menu</span>
                        {isReadyMenu ? null : <div className='flex justify-center items-center flex-col'><div className="lds-dual-ring"></div><span>Please wait</span></div>}
                        <button onClick={() => handleClick('ADD', {})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold'>Add Menu</button>
                        <div className='overflow-auto max-w-[100vw]'>
                            <table className={`table-auto w-full`}>
                                <thead>
                                    <tr className='bg-gray-300'>
                                        <th className='text-center'>Menu ID</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Display</th>
                                        <th className='text-center'>Parent Menu</th>
                                        <th className='text-center'>Thumbnail ID</th>
                                        <th className='text-center'>Show</th>
                                        <th className='text-center'>Path ID</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        menuData.map((item, index) => {
                                            return (
                                                <tr key={`user-row-${index}`}>
                                                    <td className='text-center'>{item.menu_id}</td>
                                                    <td className='text-center'>{item.menu_name}</td>
                                                    <td className='text-center'>{item.menu_display}</td>
                                                    <td className='text-center'>{item.parent_menu}</td>
                                                    <td className='text-center'>{item.thumbnail_id}</td>
                                                    <td className='text-center'>{item.is_show ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}</td>
                                                    <td className='text-center'>{item.path_id}</td>
                                                    <td className='text-center'>
                                                        <button onClick={() => handleClick('EDIT', {id: item.menu_id, name: item.menu_name, data: item})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Edit</button>
                                                        <button onClick={() => handleClick('DELETE', {id: item.menu_id, name: item.menu_name})} className='border-2 border-slate-400 rounded-lg w-32 py-2 mt-4 mb-2 hover:bg-slate-300 font-bold mr-2'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            { menuData.length <= 0 && isReadyMenu ? <span className='font-bold ml-[45%] my-5 block'>{'No record'}</span> : null }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default MenuConfig;
