import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { pageDataType } from '../../types/pageData.types';
import { viewerType } from '../../types/viewer.types';
import Styles from './pdfViewer.page.module.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { getPageData } from '../../services/viewer.service';


function PDFViewer (props: viewerType) {
    const [pageData, setPageData] = useState<Array<pageDataType>>([]);

    useEffect(() => {
        document.title = 'BiotechSG | ' + `${pageData.find((item) => item.data_name.includes('title'))?.value}`;
      }, []);

    useEffect(() => {
        try {
            getPageData(props.pageSlug)
            .then((res) => res as any)
            .then((jsonres: Array<pageDataType>) => {
                setPageData(jsonres);
            });
        } catch(err) {
            console.log(err);
        }
    }, [props])

    return (
        <div>
            <div className={`${Styles.pageContainer} mb-4`}>
                <div className={`${Styles.pageContent}`}>
                    <Header title={`${pageData.find((item) => item.data_name.includes('title'))?.value}`}/>
                    { !isMobile ? 
                        <div className={`${Styles.pdfContainer} mt-4`}>
                            <iframe 
                            src={`${process.env.REACT_APP_HOST}/util/getfile/${pageData.find((item)=>item.data_type=="PDF")?.value}`}
                            className={`${Styles.pdfFrame}`}
                            height={'100%'}
                            width={'100%'}
                            />
                        </div> : 
                        <div className={`w-100 h-[70vh] mt-5 flex justify-center items-center`}>
                            <a href={`${process.env.REACT_APP_HOST}/util/getfile/${pageData.find((item)=>item.data_type=="PDF")?.value}`} target="_blank" className={`flex justify-center border-2 max-w-fit h-10 items-center px-2 py-4 rounded-lg border-slate-900`} style={{backgroundColor: "#00bcd4"}}>
                                <FontAwesomeIcon icon={faFileArrowDown} />
                                <p className='ml-2 font-bold'>{'Download PDF file to read more details'}</p>
                            </a>
                        </div>
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PDFViewer;
